<template>
  <div>
    <div class="header">
      <v-container>
        <v-row class="d-flex justify-left">
          <v-col cols="12" md="6">
            <h1>Actualizacion de cuenta</h1>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div>
      <div class="content">
        <v-container>
        <v-row class="d-flex justify-left">
          <v-col cols="12" md="6">
            <p class="titulo" >Ha ocurrido un error.</p>
           
          </v-col>
        </v-row>
      </v-container>
      </div>
    </div>
    <div class="footer">
      <v-container>
        <v-row class="d-flex justify-left">
          <v-col cols="12" md="6">
            <img alt="logo" src="../assets/allaria-blanco.png" class="logo" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="6">
            <h6
              class="
                text-md-subtitle-1 text-h6 text-sm-center text-md-left
                font-weight-medium
              "
            >
              Allaria Ledesma & Cia S.A.
            </h6>
            <h6
              class="text-md-body-2 text-subtitle-2 text-sm-center text-md-left"
            >
              Agente de Liquidación y Compensación y Agente de Negociación
              Integral registrado bajo el N° 24 de la CNV.
            </h6>
          </v-col>
          <v-col cols="12" md="5">
            <h6
              class="
                text-md-subtitle-1 text-h6 text-sm-center text-md-right
                font-weight-medium
              "
            >
              Nuestras oficinas
            </h6>
            <h6
              class="
                text-md-body-2 text-subtitle-2 text-sm-center text-md-right
              "
            >
              25 de Mayo 359 piso 12° / CP(C1002ABG) CABA, Argentina
            </h6>
            <h6
              class="
                text-md-body-2 text-subtitle-2 text-sm-center text-md-right
              "
            >
              Tel: 54(11) 5555 6000
            </h6>
          </v-col>
          <v-col cols="1" md="1" class="d-flex justify-center">
            <a
              href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=AtwX7hIiROocA9iWdJWyyA,,"
              target="_blank"
              title="Data fiscal"
              ><img
                src="https://www.allaria.com.ar/img/data-fiscal.jpg"
                alt="Data fiscal"
                class="qr"
            /></a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Enviado",

  data: () => ({}),
  components: {},
};
</script>

<style scoped>
.qr {
  align-self: center;
}

.footer {
  background-color: #003366;
  color: white;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.header {
  background-color: #003366;
  color: white;
 /*
  position: fixed;
  top: 0px;
  */
  width: 100%;
}
h1 {
  font-weight: 300;
  margin-top: 20px;
}
.content {
  
  height: 200px;
  /*
  position: relative;
  top: 300px;
  */
}

.titulo{
    font-size: 38px;
    font-weight: 300;
    margin-top: 100px;
    margin-bottom: 5000px;
    
}

@media (max-width: 960px) {
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .titulo{
      text-align: center;
  }
}

@media(max-height: 800px){
    .footer{
        position: static;
    }
    .content{
        min-height: 300px;
    }
}
</style>
<template>

<div>

  <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
  <div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">
    <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Actividad</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" xs="7"  sm="7" md="6">
        
            <div  class="rubro-container">
          <span class="text-rubro">
            ¿Cuál es tu rubro o actividad principal?
          </span>

          <input v-model="rubroSearch" class="rubroSearch" :placeholder="'Buscar...'" >
        </div>

        <div  class="actividad-container">
          <div style="display: flex;" v-for="actividad in actividades" :key="actividad.descripcion">
            <button @click="elegirActividad(actividad.id)"  v-if="isselected(actividad.id)"  ref="{{ actividad.descripcion }}" class="actividad-selected">{{ actividad.descripcion }}  </button>          
          </div>
          <div style="display: flex;" v-for="actividad in filteredActividades" :key="actividad.descripcion">
            <button @click="elegirActividad(actividad.id)"  v-if="!isselected(actividad.id)"  ref="{{ actividad.descripcion }}" class="actividad">{{ actividad.descripcion }}  </button>          
            
          </div>
        </div>

      <h4>¿Hace cuántos años desarrollás tu actividad?</h4>

        <v-text-field             
              hide-details="auto"
              v-model="antiguedad"
              outlined
              @change="validar()"            
              @keyup="validar()"
              @input="handleInput($event)"
              dense
             hint="*En caso de estar desempleado o ser jubilado, indicar dicha antigüedad"
             persistent-hint
             type="number"
             min='0'
            ></v-text-field>

              


            <button @click="continuar()" class="continuar" :disabled="!validado" >Continuar</button>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </transition>
  
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  components: {
    
  },
  data() {
    return {
      token: "5e91eee9e79b232efb5486e85f0abe835a866f3d",
      actividades: [],
      selectedactividades: [],
      rubroSearch: "",
      actividadElegida: null,
      antiguedad: null,

      validado: false,
      display: false,
    };
  },
  computed:{
    filteredActividades(){
      return this.actividades.filter(actividad => {
        return actividad.descripcion.toLowerCase().match(this.rubroSearch.toLowerCase())
      })
    }
  },
  mounted() {
    this.display = true
    this.token = localStorage.getItem('token')

      const options = {
        method: 'get',
       
        url: process.env.VUE_APP_BACKURL + `/getActividades`,  
      };
      axios(options)
        .then((result) => {
          this.actividades = result.data
          console.log(result.data.results)
      })
      .catch(() => {
       console.log('error')
      })

      this.validar();
  },

  watch: {},
  methods: {
    elegirActividad(e){
      
      
      let actividadElegida = e;
      
        
      let index = 0;
      let found = -1;

      this.selectedactividades.forEach(entry => {
        if(entry == actividadElegida){
          found = index;
        }
        index++;
      });

      if(found != -1){
        this.selectedactividades.splice(found,1);      
      } else {
          this.selectedactividades.push(actividadElegida);     
      }
      this.validar();
    },
    isselected(e){
      
      let actividad = e;
      let found = false;

      this.selectedactividades.forEach(i => {
        
        if(i == actividad){
          found = true;
        }
      });

      return found;

    },
continuar(){
      this.$session.set('actividades', this.selectedactividades);
      this.$session.set('antiguedad', this.antiguedad);
      window.scrollTo(0, 0);
      
      
      this.display = false
    setTimeout(() => {  this.$router.push("/paso4"); }, 400);
    },
  validar(){
    if(this.selectedactividades.length == 0 || this.antiguedad == null || this.antiguedad < 0 || this.antiguedad == '-' || this.antiguedad == '.' ||  this.antiguedad == '' || this.antiguedad % 1 != 0){
      this.validado = false;
    } else {
      this.validado = true;
    }
  },
  handleInput(){
     this.validar();
  }
  },
};
</script>

<style scoped>


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
    font-family: 'Lato', sans-serif;
  }


.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}



.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}



.body {
  background-color: black;
}






.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  
}
.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.rubro-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  
  .text-rubro{
    width: 320px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }

  .rubroSearch{
    width: 115%;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px;
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
    align-self: center;
  }

  .actividad-container{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 210px;
    margin-bottom: 20px;
    overflow-y: scroll;
   
    
  }


  h4 {
  line-height: 20px;
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 15px;

}

  .actividad{
    /* width: 320px; */
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: left;
  }

   .actividad-selected{
    /* width: 320px; */
    color: #0c5297;
    font-size: 13px;
    font-weight: 800;
    margin-bottom: 10px;
    text-align: left;
    text-shadow: 1px 0px #0c5297;
  }

  .actividad:hover{
    text-decoration: underline;
  }

.actividad-selected:hover{
    text-decoration: underline;
  }

.actividad-container::-webkit-scrollbar {
    width: 12px;
}

.actividad-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
    border-radius: 10px;
}

.actividad-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}


  h1 {
    font-size: 38px;
    padding-top: 10%;
    text-align: center; 
    font-weight: 800;
    
  }


@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

  .rubroSearch{
    width: 100%;
    
  }

  
}


@media  (max-width: 502px) {
.continuar{
  position:relative;
  top:45px;
}

}







</style>
import Vue from 'vue'
import App from './App.vue'
import VueSessionStorage from "vue-sessionstorage";
import router from './router'
import vuetify from './plugins/vuetify'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'





import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'




library.add(faCircle)
library.add(faTrashCan)

Vue.component('fa', FontAwesomeIcon)
Vue.use(VueSessionStorage);
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

import Inicio from '../views/Inicio.vue'
import Paso1 from '../views/Paso1.vue'
import Paso2 from '../views/Paso2.vue'
import Paso3 from '../views/Paso3.vue'
import Paso4 from '../views/Paso4.vue'
import Paso5 from '../views/Paso5.vue'
import Paso6 from '../views/Paso6.vue'
import Paso7 from '../views/Paso7.vue'
import Finalizado from '../views/Finalizado.vue'
import Error from '../views/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/actualizarSolicitud/:id',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/paso1',
    name: 'Paso1',
    component: Paso1,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/paso2',
    name: 'Paso2',
    component: Paso2,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/paso3',
    name: 'Paso3',
    component: Paso3
  },
  {
    path: '/paso4',
    name: 'Paso4',
    component: Paso4
  },
  {
    path: '/paso5',
    name: 'Paso5',
    component: Paso5
  },
  {
    path: '/paso6',
    name: 'Paso6',
    component: Paso6
  },
  {
    path: '/paso7',
    name: 'Paso7',
    component: Paso7
  },
  {
    path: '/enviado',
    name: 'Formulario enviado',
    component: Finalizado
  },
  {
    path: '/error',
    name: 'Error en Formulario',
    component: Error
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router

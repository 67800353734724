<template >
  <v-app class="bg">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
#app {
  
background-image: url("assets/fondo.png")
}

.header{

  background-color: white;
  border-bottom: solid 3px;
  border-color: #0c5297;
  display:flex;
  justify-content: flex-start;
  
}


.logo{
  height: 60px;
  width:180px;
  margin-left: 15px;
  margin-top: 7px;
  margin-bottom: 10px;
  /* position: relative;
  bottom: 5px */
}

@media (max-width: 480px){
  
.header{
  justify-content: center;
}
 .logo{
 
 margin-left: 0px;
}
}

</style>
<template>
  

  <div>
    <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
    <div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">


    <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Preguntas Patrimoniales</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" sm="7" lg="6" style="margin-top: 0 px">
            <div class="fields">

            
            <div v-for="(pregunta, index) in preguntas" :key="pregunta">
              <h4>{{ pregunta.descripcion }}</h4>

              <v-select
                :items="opciones[index]"
                outlined
                dense
                v-model="respuestas[index]"
                @change="validar()"
              ></v-select>
            </div>

            <h4>¿Sos una persona expuesta políticamente?</h4>
            <v-select
              :items="listapep"
              outlined
              dense
              v-model="pep"
              hide-details
              @change="chequearpep()"
              style="margin-bottom: 0px;"
            ></v-select>
            <button style="">
              <p class="ejemplo" @click="cerrarModalpep()">*¿Que es ser PEP?</p>
            </button>

            <h4 v-if="espep">
              Por favor, aclará el cargo desempeñado o el vínculo que te define
              como PEP
            </h4>

            <v-text-field
              v-if="espep"
              hide-details="auto"
              v-model="causapep"
              outlined
              dense
              style="margin-bottom: 30px"
              @change="validar()"
              @keyup="validar()"
            ></v-text-field>
            <div >
              <h4>¿Cuál es tu residencia fiscal?</h4>
              <v-select
                :items="listaresidencia"
                outlined
                dense
                v-model="residenciafiscal"
                @change="chequearresidencia(), validar()"
              ></v-select>
              <h4 v-if="esresidenteexterior">
                Por favor, indica país de residencia fiscal
              </h4>

              <v-select
                v-if="esresidenteexterior"
                :items="paisesresidenciaopciones"
                outlined
                dense
                v-model="paisderesidencia"
                hide-details
                @change="validar()"
              ></v-select>

              <h4 style="margin-top:35px" v-if="esresidenteexterior">Por favor, indique el NIT:</h4>

              <v-text-field
                v-if="esresidenteexterior"
                hide-details="auto"
                v-model="nit"
                outlined
                dense
                style=""
                @change="validar()"
                @keyup="validar()"
              ></v-text-field>
              <p v-if="esresidenteexterior" class="nit">* Número de Identificacion Tributaria</p>
            </div>
            </div>
            <button
              @click="continuar()"
              class="continuar"
              :disabled="!validado"
            >
              Continuar
            </button>

            <transition name="fade">
              <div class="modal-fade" v-if="modalejemplopep">
                <div class="modal-content-dni">
                  <button @click="cerrarModalpep()" class="cerrar-modal">
                      x
                    </button>
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" sm="12" md="12">
                        <div class="rubro-container">
                          <span class="text-rubro">
                            Si Ud. se encuentra identificado en los items
                            mencionados debajo por la norma, a saber:
                          </span>
                        </div>

                        <div class="actividad-container">
                          <div
                            style="display: flex"
                            v-for="item in items"
                            :key="item"
                          >
                            <p class="actividad">{{ item }}</p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>                           
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="modal-fade" v-if="showDebenCargarDoc">
                <div class="modal-content-patrimonial" >
                    <v-row class="d-flex justify-center">
                      <v-col cols="12" sm="12" md="12">
                        <div class="texto-patrimonial-container">
                          <span class="text-patrimonial">
                            Debido a que declaró montos máximos en su ingreso mensual aproximado y/o patrimonio total
                            por favor, en el último paso, adjunte documentación patrimonial respaldatoria.
                          </span>
                        </div>
                      </v-col>
                    </v-row>   
                    <v-row justify="center" >
                      <v-col cols="3"  class="d-flex justify-center"  >
                        <button @click="cerrarPatrimonialModal()" class="cerrar-modal-patrimonial">
                      Ok
                    </button>
                      </v-col>
                    </v-row>                        
                </div>
              </div>
            </transition>
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </transition>
  
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      respuestas: [],
      opciones: [],
      preguntas: [],
      paises: [],
      paisesresidenciaopciones: [],

      lastoptionstr: [],
      showDebenCargarDoc: false,
      yaFueNotificadoDoc: false,

      pep: null,
      causapep: null,
      residenciafiscal: null,
      paisderesidencia: null,
      nit: null,
      espep: false,
      

      esresidenteexterior: false,
      modalejemplopep: false,
      validado: false,

      listaresidencia: ["Argentina", "Distinto de Argentina"],
      listapep: ["No", "Sí, directamente", "Sí, por vínculo familiar"],

      items: [
        "UNIDAD DE INFORMACIÓN FINANCIERA",
        "Resolución 35/2023 RESOL-2023-35",
        'PERSONAS EXPUESTAS POLÍTICAMENTE EXTRAJERAS.',
        'Son cosideradas Personas Expuestas Políticamente Extranjeras los funcionarios públicos pertenecientes a países extranjeros que se desempeñen o se hayan desempeñado en alguna de las siguientes funciones:',
        'a) Jefe de Estado, jefe de Gobierno, Gobernador, Intendente, Ministro, Secretario, Subsecretario de Estado u otro cargo gubernamental equivalente.',
        'b) Miebro del Parlamento, Poder Legislativo o de otro órgano de naturaleza equivalente.',
        'c) Juez o Magistrado de Tribunales Superiores u otra alta instancia judicial o administrativa, en el ámbito del Poder Judicial.',
        'd) Embajador o cónsul de un país u organismo internacional.',
        'e) Autoridad, apoderado, integrante del órgano de administración o control dentro de un partido político extranjero.',
        'f) Oficial de alto rango de las Fuerzas Armadas (a partir de coronel o grado equivalente en la fuerza y/o país de que se trate) o de las fuerzas de seguridad pública (a partir de comisario o rango equivalente según la fuerza y/o país de que se trate).',
        'g) Miembro de los órganos de dirección y control de empresas de propiedad estatal.',
        'h) Miembro de los órganos de direccion o control de empresas de propiedad privada o mixta cuando el Estado posea una participación igual o superior al VEINTE POR CIENTO (20%) del capital o del derecho a voto, o que ejerza de forma directa o idirecta el control de dicha entidad.',
        'i) Presidente, vicepresidente, director, gobernador, consejero, síndico o autoridad equivalente de bancos centrales y otros organismos de regulación y/o supervisión del sector financiero.',
        'j) Representantes consulares, miembros de la alta grencia, como son los directores y mienbros de la junta, o cargos equivalentes, apoderados y representantes legales de una organización internacional, con facultades de decisión, administración o disposición.',
        'PERSONAS EXPUESTAS POLÍTICAMENTE NACIONALES, PROVINCIALES, MUNICIPALES O DE LA CIUDAD AUTÓNOMA DE BUENOS AIRES.',
        'Son consideradas Personas Expuestas Políticamente nacionales, provinciales, municipales o de la Ciudad Autónoma de Buenos Aires, los funcionarios públicos de dichas jurisdicciones que se desempeñen o se hayan desempeñado en alguno de los siguientes cargos:',
        'a) Presidente o Vicepresidente de la Nación.',
        'b) Legislador nacional, provincial, municipal o de la Ciudad Autónoma de Buenos Aires.',
        'c) Gobernador, Vicegobernador, Intendente, Vice-intendente, Jefe de Gobierno o vicejefe de Gobierno.',
        'd) Jefe de Gabinete de Ministros, Ministro, Secretario o Subsecretario del Poder Ejecutuvo de la Nación, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.',
        'e) Personal del Poder Judicial de la Nación o del Ministerio Público de la Nación, con categoría no inferior a Secretarío, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.',
        'f) Defensor del Pueblo de la Nación, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires y los adjuntos del Defensor del Pueblo.',
        'g) Interventor federal, o colaboradores del mismo con categoría no inferior a Director o su equivalente.',
        'h) Personal del Poder Legislativo de la Nación, con categoría no inferior a la de Director, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.',
        'i) Síndico General de la Nación o Síndico General Adjunto de la Sindicatura General de la Nación; Presidente o Auditor General de la Auditoría General de la Nación; autoridad superior de un ente regulador o de los demás órganos que integran los sistemas de control del sector público nacional; miembros de organismos jurisdiccionales administrativos o personal de dichos organismos, con categoría no infreior a la de Director o su equivalente.',
        'j) Miembro del Consejo de la Magistratura de la Nación o del jurado de Enjuiciamiento, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.',
        'k) Embajador o Cónsul',
        'l) Personal de las Fuerzas Armadas, de la Policía Federal Argentina, de Gendarmería Nacional, de la Prefectura Naval Argentina, del Servicio Penitenciario Federal o de la Policía de Seguridad Aeroportuaria con jerarquía no inferiro a la de coronel o grado equivalente según la fuerza, su equivalente en las provincias o en la Ciudad Autónoma de Buenos Aires.',
        'm) Rector, Decano o Secretario de las Universidades Nacionales o provinciales.',
        'n) Funcionario o empleado con categoría o función no inferior a la de Director General o Nacional, de la Administración Pública Nacional, centralizada o descentralizada, de entidades autárquicas, bancos y entidades financieras del sistema oficial, de las obras sociales administradas por el Estado, de empresas y sociedades del Estado nacional o provincial y el personal con similar categoría o función, designado a propuesta del Estado en sociedades de economía mixta, sociedades anónimas con participación estatal mayoritaria o en otras empresas o entes del sector público.',
        'ñ) Funcionario o empleado público con poder decisorio de un organismo estatal encargado de otrogar habilitaciones administrativas, permisos o concesiones, para el ejercicio de cualquier actividad; y de controlar el funcionamiento de dichas actividades o de ejercer cualquier otro control en virtud de un poder de policía.',
        'o) Funcionario público de los organismos de control de servicios públicos, con categoría no inferior a la de Director General, nacional, provincial o de la Ciudad Autónoma de Buenos Aires.',
        'p) Funcionario o empleado público con poder de decisión que integre comisiones de adjudicación de licitaciones, de compra o de recepción de bienes y servicios o que participe en la toma de decisiones de esas licitaciones o compras.',
        'q) Funcionario público responsable de administrar un patrimonio púbico o privado, o controlar o fiscalizar los ingresos públicos de cualquera fuera su naturaleza.',
        'r) Director o Administrador de alguna de las entidades sometidas al control externo del Honorable Congreso de la Nación, de conformidad con lo dispuesto en el artículo 120 de la Ley N° 24.156',
        'OTRAS PERSONAS EXPUESTAS POLÍTICAMENTE.',
        'Sin perjuicio de lo expuesto en los artículos precedentes, son, asimismo, cosideradas Personas Expuestas Politicamente las siguientes:',
        'a) Autoridad, apoderado, candidato o miembro relevante de partidos políticos o alianzas electorales, ya sea a nivel nacional o distrital, de conformidad con lo establecido en las Leyes Nros. 23.298 y 26.215.',
        'b) Autoridad de los órganos de dirección y administración de organizaciones sindicales y empresariales (cámaras, asociaciones y otras formas de agrupación corportativa). Con respecto a las organizaciones sindiciales, el alcance comprende a las personas humanas con capacidad de decisión, administración, control o disposición del patrimonio de la organización sindicial. Con respecto a las organizaciones empresariales, el alcance comprende a las personas humanas de las mencionadas organizaciones que, en su función de su cargo:',
        '       1) Tengan capacidad de decisión, administración, control o disposición sobre sus fondos provenientes del sector público nacional, provincial, municipal o de la ciudad Autónoma de buenos Aires y/o,',
        '       2) Realicen actividades con fines de lucro para la organización o sus representados, que involucren a la gestión intermediación o contratación habitual con el Estado nacional, provincial, municipal o de la Ciudad autónoma de Buenos Aires,',
        'c) Autoridad, representante legal, integrante del órgano de administración o de la comisión Directiva de las obras sociales contempladas en la Ley N° 23.660. El alcance comprende a las personas humanas de las mencionadas organizaciones con capacidad de decisión, administración, control o disposicíon del patrimonio de las mismas.',
        'd) Las personas humanas con capacidad de decisión, administración, control o disposición del patrimonio de personas jurídicas privadas en los términos del artículo 148 del Código Civil y Comercial de la Nación, que reciban fondos públicos destinados a terceros y cuenten con poder de control y disposición respecto del destino de dichos fondos.',
        'PERSONAS EXPUESTAS POLÍTICAMENTE POR PARENTESCO O CERCANÍA.',
        'Se consideran Personas Expuestas Políticamente por parentesco o cercanía a aquellas que mantienen, con las individualizadas en los articulos 1° a 3° de la presente, cualquiera de los siguientes vínculos:',
        'a) Cónyuge o conviviente.',
        'b) Familiares de línea ascendente, descendente y colateral hasta el segundo grado de consanguinidad o afinidad (abuelos/as, padres/madres, hermanos/as, hijos/as, nietos/as, suegros/as, yernos/nueras, cuñados/as).',
        'c) Personas allegadas o cercanas: debe entederse como tales a aquellas que mantengan relaciones jurídicas de negocios del tipo asociativas, aún de carácter informal, cualquiera fuese su naturaleza.',
        'd) Toda otra relación o vínculo que por sus características y en función de un análisis basado en riesgo, a criterio del Sujeto Obligado, pueda resultar relevante.',
        'MANTENIMIENTO DE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE.',
        'Las Personas Expuestas Políticamente, a la que aluden los artículos 1° a 3° de la presente, mantendrán tal condición mientras ejerzan el cargo o desempeñen la función y hasta transcurridos DOS (2) años desde el cese en los mismos. Una vez cumplido el plazo de los DOS (2) años, el Sujeto Obligado deberá evaluar el nivle de riesgo del cliente o beneficiario final tomando en cosideración la relevancia de la función pública ejercida, entre otros factores de relevancia para enl análisis del nivel de riesgo. Las Personas Expuestas Políticamente por parentesco o cercanía mantendrán su condición por el mismo tiempo que el de la persona con la que tienen o hayan tenido el vínculo.'
      ],

      display: false,
    };
  },

  watch: {},
  mounted() {
    this.display = true
    this.getPreguntas();
    this.getPaises();
  },
  methods: {
    continuar() {
      this.normalizarPep();
      this.$session.set("pep", this.pep);
      this.$session.set("causapep", this.causapep);
      this.$session.set("patrimonio",  this.convertirRespuestasToId(this.respuestas));
      if(this.residenciafiscal =='Distinto de Argentina'){

        this.$session.set("paisderesidenciafiscal", this.buscaPaisId(this.paisderesidencia));
      } else {
        this.$session.set("paisderesidenciafiscal", this.buscaPaisId('Argentina'));
      }
      this.$session.set("nit", this.nit);

      window.scrollTo(0, 0);
      
      this.display = false
      setTimeout(() => {  this.$router.push("/paso7"); }, 400);
    },
    chequearpep() {
      if (
        this.pep == "Sí, directamente" ||
        this.pep == "Sí, por vínculo familiar"
      ) {
        this.espep = true;
      } else {
        this.espep = false;
        this.causapep = "";
      }
      this.validar();
    },
    chequearresidencia() {
      if (this.residenciafiscal == "Distinto de Argentina") {
        this.esresidenteexterior = true;
      } else {
        this.esresidenteexterior = false;
        this.nit = null;
        this.paisderesidencia = null;
      }
    },
    cerrarModalpep() {
      if (this.modalejemplopep == true) {
        this.modalejemplopep = false;
      } else {
        this.modalejemplopep = true;
      }
    },
    cerrarPatrimonialModal(){
      this.showDebenCargarDoc = false;
    },
    getPreguntas() {
      let data = {
        tipo: "patrimonio",
      };
      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_BACKURL + "/obtenerPreguntas",
      };
      axios(options)
        .then((result) => {
          this.preguntas = result.data;
          this.normalizarRespuestas();
          this.cargarRespuestasPatrimoniales();
          console.log();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    normalizarRespuestas() {
      this.preguntas.forEach((pregunta) => {
        let r = [];
        pregunta.opcion.forEach((opcion) => {
          r.push(opcion.descripcion);
        });
        this.opciones.push(r);
      });
    },
    cargarRespuestasPatrimoniales(){

      this.preguntas[0].opcion.forEach(o => {
        this.lastoptionstr[0] = o.descripcion;
      });

      this.preguntas[1].opcion.forEach(o => {
        this.lastoptionstr[1] = o.descripcion;
      });

    }, 
    normalizarPep(){
      if(this.pep == 'Sí, directamente'){
        this.pep = "Directo";
      } else if (this.pep == 'Sí, por vínculo familiar'){
        this.pep = "Indirecto"
      }
    },
    validar() {
      if (
        this.respuestas.length < this.preguntas.length ||
        this.pep == null ||
        this.residenciafiscal == null
      ) {
        this.validado = false;
      } else if (this.espep == true && this.causapep.trim() == "") {
        this.validado = false;
      } else if (
        this.esresidenteexterior == true &&
        (this.paisderesidencia == null || this.nit.trim() == "")
      ) {
        this.validado = false;
      } else {
        this.validado = true;
      }

      if((this.respuestas[0] == this.lastoptionstr[0] || this.respuestas[1] == this.lastoptionstr[1]) && !this.yaFueNotificadoDoc){

        this.yaFueNotificadoDoc = true;
        this.showDebenCargarDoc = true;
      }


    },
    async getPaises() {
      this.token = localStorage.getItem("token");

      const options = {
        method: "get",
        url: process.env.VUE_APP_BACKURL + `/getPaises`,
      };
      axios(options)
        .then((result) => {
          this.paises = result.data;
          this.setItems();
        })
        .catch(() => {
          console.log("error");
        });
    },
    setItems() {
      this.paises.forEach((pais) => {
        this.paisesresidenciaopciones.push(pais.nacionalidad);
      });
    },
    convertirRespuestasToId() {
      let index = 0;
      let result = [];
      this.respuestas.forEach((r) => {
        this.preguntas[index].opcion.forEach((o) => {
          if (o.descripcion == r) {
            let x = [];
            x[0] = o.pregunta_id;
            x[1] = o.id;
            result.push(x);
          }
        });
        index++;
      });
      return result;
    },
    buscaPaisId(p){
      let id = -1
        this.paises.forEach(pais => { 
            if(pais.descripcion == p){
              id = pais.id
            }
        });
        return id
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  font-family: "Lato", sans-serif;
}

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.body {
  background-color: black;
}

.actividad-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 60vh;
    
    margin-bottom: 50px;
  }

h1 {
    font-size: 38px;
    padding-top: 10%;
    text-align: center;
    font-weight: 800;
  }



h4 {
  line-height: 20px;
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
   margin-bottom: 10px;
}
.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 0px;
  padding: 500px;
}


.fields {
  margin-top: 45px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.fields::-webkit-scrollbar {
  width: 12px;
}

.fields::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
  
}



.fields::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}


.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 75px;
  margin-bottom: 20px;
  
}
.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.ejemplo {
  font-weight: bold;
  font-size: 13px;
  /* text-decoration-line: underline; */
  color: #0c5297;
  width: 300px;
  margin: 0 auto;
  text-align: left;
  

  padding-bottom: 5px;
}

.nit {
  font-weight: bold;
  font-size: 13px;
  /* text-decoration-line: underline; */
  
  width: 300px;
  margin: 0 auto;
  text-align: left;
  

  padding-bottom: 5px;
}

.ejemplo:hover {
  text-decoration: underline;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 1s ease;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 1s ease;
}

.fade-standard-enter-from {
  opacity: 0;
}
.fade-standard-enter-to {
  opacity: 1;
}
.fade-standard-enter-active {
  transition: all 1s ease;
}
.fade-standard-leave-from {
  opacity: 1;
}
.fade-standard-leave-to {
  opacity: 0;
}
.fade-standard-leave-active {
  transition: all 1s ease;
}

.modal-fade-standard {
  width: 100%;
  height: 3000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  right: 50%;
  transform: translate(50%, 0);
  padding-top: 250px;
  position: fixed;
  top: 0px;
  left: 0px;
}



.rubro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
.texto-patrimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  
}

  .cerrar-modal {
    background-color: #0c5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 30px;
    padding-bottom: 4px;
    border-radius: 5px;
    border: none;

    position: sticky;
    right: 10px;
    top: 10px;
   
  }

  
  .cerrar-modal-patrimonial {
    background-color: #0c5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 50px;
    border-radius: 5px;
    margin-bottom: 20px;
    
    
    
  }

  .text-rubro {
    width: 90%;
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }

.text-patrimonial {
    width: 90%;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: center;
  }

 .modal-content-dni {
    width: 50%;
    overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
    margin-top: 0px; 
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 100px;
    left: 25%;
    text-align: right;
  }

.modal-content-patrimonial {
    width: 25%;
    overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden; 
  max-height: 600px;
    margin-top: 0px; 
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 200px;
    left: 37.5%;
    text-align: right;
  }

 
.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}

.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}


.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);

}

.actividad {
  /* width: 320px; */
  color: #000000;
  font-size: 14px;
  font-weight: 900;

  text-align: left;
}

@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

  .modal-content-dni{
    width: 90%;
    position: fixed;
    top: 30px;
    left: 2%;
  }

  .modal-content-patrimonial{
  width: 90%;
    position: fixed;
    top: 30px;
    left: 4%;

  }
  
  
}




</style>
<template>
  <div>
    <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
    <div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  
   <v-row class="d-flex justify-center" v-if="display">
    
    
    <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Datos Personales</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" sm="7" md="6">
            <div class="fields">
              <v-select
                :items="paisdescripcion"
                label="País"
                outlined
                dense
                v-model="paisselected"
                class="top-inputs"
                @change="chequearPaisArgentina()"
                style="margin-top: 5px"
              ></v-select>
              <v-select
                :items="provinciadescripcion"
                label="Provincia"
                outlined
                dense
                v-model="provinciaselected"
                class="top-inputs"
               @change="validar()"
                @keyup="validar()"
                v-show="provinciashabilitado" 
              ></v-select>

              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="8">
                  <v-text-field
                    label="Calle"
                    persistent-placeholder
                    hide-details="auto"
                    v-model="calle"
                    outlined
                    dense
                    class="top-inputs"
                    @change="validar()"
                @keyup="validar()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Altura"
                    persistent-placeholder
                    hide-details="auto"
                    v-model="altura"
                    outlined
                    dense
                    class="top-inputs"
                    @change="validar()"
                @keyup="validar()"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Piso"
                    persistent-placeholder
                    hide-details="auto"
                    v-model="piso"
                    outlined
                    dense
                    
                    persistent-hint
                    class="piso"
                    @change="validar()"
                @keyup="validar()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Departamento"
                    persistent-placeholder
                    hide-details="auto"
                    v-model="departamento"
                    outlined
                    dense
                    
                    persistent-hint
                    class="hinted"
                    @change="validar()"
                @keyup="validar()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Localidad"
                    hide-details="auto"
                    v-model="localidad"
                    outlined
                    dense
                    persistent-placeholder
                   @change="validar()"
                @keyup="validar()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Codigo Postal"
                    hide-details="auto"
                    v-model="codigopostal"
                    outlined
                    dense
                    class="cp"
                    persistent-placeholder
                   @change="validar()"
                @keyup="validar()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                label="Teléfono"
                hide-details="auto"
                v-model="telefono"
                outlined
                dense
                persistent-placeholder
                style="margin-top: 25px; margin-bottom: 25px"
                @change="validar()"
                @keyup="validar()"
                @keypress="isNumber($event)"
              ></v-text-field>
              <v-text-field
                label="Correo"
                hide-details="auto"
                v-model="correo"
                outlined
                dense
                persistent-placeholder
                style="margin-top: 25px; margin-bottom: 25px"
                @change="validar()"
                @keyup="validar()"
              ></v-text-field>
              <v-text-field
                label="Correos adicionales"
                hide-details="auto"
                v-model="correosadicionales"
                outlined
                dense
                persistent-placeholder
                style="margin-top: 25px; margin-bottom: 25px"
                hint="*opcional, ingresar correos separados por ;"
                persistent-hint
                @change="validar()"
                @keyup="validar()"
              ></v-text-field>
            </div>
            <button
              @click="continuar()"
              class="continuar"
              :disabled="!validado"
            >
              Continuar
            </button>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </transition>
 
  </div>
</template>

<script>
import axios from "axios";
import RegexHandler from "../handlers/RegexHandler"
export default {
  name: "Home",
  components: {},
  data() {
    return {
      calle: "libertador",
      altura: "1241",
      piso: "",
      departamento: "",
      localidad: "",
      codigopostal: "1646",
      telefono: "47450123",
      correo: "tomas.pamphilis@allaria.com.ar",
      correosadicionales: "",
      paisselected: null,
      provinciaselected: null,
      paisselectedid: -1,

      validado: false,

      paises: [],
      provincias: [],

      paisdescripcion:[],
      provinciadescripcion:[],

      provinciashabilitado: false ,

      display: false,
    };
  },

  watch: {},
  methods: {
    continuar() {
      this.$session.set("calle", this.calle);
      this.$session.set("altura", this.altura);
      this.$session.set("piso", this.piso);
      this.$session.set("departamento", this.departamento);
      this.$session.set("localidad", this.localidad);
      this.$session.set("codigopostal", this.codigopostal);
      this.$session.set("telefono", this.telefono);
      this.$session.set("correo", this.correo);
      this.$session.set("correosadicionales", this.correosadicionales);
      this.$session.set("pais", this.buscarPaisId(this.paisselected));
      this.$session.set("provincia", this.buscarProvinciaId(this.provinciaselected));

      window.scrollTo(0, 0);


      this.display = false
    setTimeout(() => {  this.$router.push("/paso3"); }, 400);
      
    },
    async getPaises() {
      this.token = localStorage.getItem("token");

      const options = {
        method: "get",
        url: process.env.VUE_APP_BACKURL + `/getPaises`,
      };
      axios(options)
        .then((result) => {
           this.paises = result.data;
         
            this.setItems();
          
        })
        .catch(() => {
          console.log("error getpaises");
        });
    },
    async getProvincias(){
       this.token = localStorage.getItem("token");

      const options = {
        method: "get",
        url: process.env.VUE_APP_BACKURL + `/getProvincias`,
      };
      axios(options)
        .then((result) => {
      
          this.provincias = result.data;
          this.setItems();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    setItems(){


      let pais_id = this.$session.get("pais")
       let provincia_id = this.$session.get("provincia")

      

      this.paises.forEach(p => {
        this.paisdescripcion.push(p.descripcion)

        if(p.id == pais_id){
          this.paisselected = p.descripcion
        }
        
      });
        
      this.chequearPaisArgentina()

      this.provincias.forEach(p => {
        this.provinciadescripcion.push(p.descripcion)
       
        if(p.id == provincia_id){
          this.provinciaselected = p.descripcion
        }
      });

      this.calle = this.$session.get('calle')
      this.altura = this.$session.get('altura')
      this.piso = this.$session.get('piso')
      this.departamento = this.$session.get('departamento')
      this.localidad = this.$session.get('localidad')
      this.correo = this.$session.get('correo')
      this.correosadicionales = this.$session.get('correosadicionales')
      this.telefono = this.$session.get('telefono')
      this.codigopostal = this.$session.get('codigopostal')

      this.validar()


    },
    validar() {

      // ESTE FIX ROMPE LOS CAMPOS Y BORRA LO QUE SE ESCRIBE, VER SI AL COMENTAR EL ERROR ORIGINAL SIGUE O NO
      // los datos pueden llegar a ser null desde el back y rompe la validacion 

      // if (this.calle == null) this.calle = ""
      // if(this.altura == null) this.altura = ""
      // if(this.localidad == null) this.localidad = ""
      // if(this.codigopostal == null) this.codigopostal = ""
      // if(this.telefono == null) this.telefono = ""
      // if(this.correo == null) this.correo = ""
      // if(this.paisselected == null) this.paisselected = ""
      // if(this.provinciaselected == null) this.provinciaselected = ""
      
      let calle = this.calle
      if(calle == null) calle = ""
      let altura = this.altura
      if(altura == null) altura = ""
      let localidad = this.localidad
      if(localidad == null) localidad = ""
      let codigopostal = this.codigopostal
      if(codigopostal == null) codigopostal = ""
      let telefono = this.telefono
      if(telefono == null) telefono = ""
      let correo = this.correo
      if(correo == null) correo = ""
      let paisselected = this.paisselected
      if(paisselected == null) paisselected = ""
      let provinciaselected = this.provinciaselected
      if(provinciaselected == null) provinciaselected = ""

      if (  
        calle.trim() == "" ||
        altura.trim() == "" ||
        localidad.trim() == "" ||
        codigopostal.trim() == "" ||
        telefono.trim() == "" ||
        !RegexHandler.validatePhoneNumber(telefono) ||
        correo.trim() == "" ||
        paisselected.trim() == "" ||
        (paisselected == "Argentina" && provinciaselected.trim() == "" )
        
      ) {
        this.validado = false;
      } else {
        this.validado = true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    chequearPaisArgentina(){
      if(this.paisselected == 'Argentina'){
      
        this.provinciashabilitado = true
      } else{
        this.provinciaselected = ""
        this.provinciashabilitado = false
      }
      this.validar()
    },
    buscarPaisId(descripcion){
        let id = null
        this.paises.forEach(pais => { 
            if(pais.descripcion == descripcion){
              id = pais.id
            }
        });
        return id
    },
    buscarProvinciaId(descripcion){
      let id = null
        this.provincias.forEach(provincia => { 
            if(provincia.descripcion == descripcion){
              id = provincia.id
            }
        });
        return id
    }
  },
  mounted() {
    this.display = true
    this.getPaises();
    this.getProvincias();
   // this.validar();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  font-family: "Lato", sans-serif;
}

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}



.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.body {
  background-color: black;
}

.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 45px;
  margin-bottom: 20px;
  
}
.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center;
  font-weight: 800;
}

.fields {
  margin-top: 25px;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.fields::-webkit-scrollbar {
  width: 12px;
}

.fields::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
  
}



.fields::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}


.hinted {
  position: relative;
  top: 0px;
}

.piso {
  position: relative;
  top: 0px;
}





@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

  
}






@media (max-width: 800px) {
  .continuar {
    position: relative;
    top: 20px;
    margin-bottom: 46px;
  }
}

















</style>
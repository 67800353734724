<template>
  <div> 
      <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
      <div class="header">

      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">
    <v-col cols="12" sm="12" md="6" lg="5" xl="4" >
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb" >
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>¡Listo!</h1>
            <h2>¡Muchas gracias por responder!</h2>           
            <h2 style="margin-top : 0px">Tu información ha sido enviada con éxito.</h2>
          </v-col>
        </v-row>
  <v-row class="d-flex justify-center" style="flex-direction: column;  align-items: center">
          <img alt="Allaroa" src="../assets/allaria-icon-large.png" class="icon">
          <p>Seguinos en nuestras redes</p>
          <div class="redes-container">
        <a href="https://www.facebook.com/allarialedesmaycia/" target="_blank" rel="noopener noreferrer"><img src="../assets/facebook.png" alt="Facebook" class="redes-icon"></a>
        <a href="https://twitter.com/allarialedesma" target="_blank" rel="noopener noreferrer"><img src="../assets/twitter.png" alt="Twitter" class="redes-icon"></a>
        <a href="https://www.linkedin.com/company/allarialedesma" target="_blank" rel="noopener noreferrer"><img src="../assets/linkedin.png" alt="Linkedin" class="redes-icon"></a>
        <a href="https://www.instagram.com/allarialedesma" target="_blank" rel="noopener noreferrer"><img src="../assets/instagram.png" alt="Instagram" class="redes-icon"></a>
          </div>
  </v-row>
  <v-row class="d-flex justify-center">
      <v-col cols="10" sm="7" md="6">

      <button @click="continuar()" class="continuar" >Ingresá a la web de Allaria</button>
      </v-col>
  </v-row>
      </v-card>
    </v-col>
  </v-row>
       </transition>
       
  </div>
</template>

<script>

export default {
  data() {
    return {
        display: false,
     };
  },
  name: "Enviado",
  components: {},
  methods: {
      continuar(){
          
          window.location.href = "https://www.allaria.com.ar/";
      }
  },
  mounted() {
      this.display = true
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  font-family: "Lato", sans-serif; 
}

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}



h1 {
  font-size: 58px;
  
  text-align: center;
  font-weight: 600;
  margin-top: 50px;
}

h2{
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}

p{
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
}
.icon{
    margin-top: 25px;
    margin-bottom: 20px;
    height: 195px;
    width: 195px;
    margin-left: auto;
    margin-right: auto;
    
}

.redes-container{
    display: flex;
    justify-content: space-between;
    width: 250px;

}
.redes-icon{
    height: 40px;
    width: 40px
}

.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 100px;
  margin-bottom: 20px;
  
}

@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

 
}










  







.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
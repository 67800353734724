<template>
<div>
<link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
<div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">
    <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Perfil de Riesgo</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" sm="7" lg="6" style="margin-top: 0px;">
            <div class="fields">

            <div v-for="(pregunta, index) in preguntas" :key="pregunta" >

            
            <h4>{{pregunta.descripcion}}</h4>
          
            <v-select
              :items="opciones[index]"
              outlined
              dense
              v-model="respuestas[index]"
              @change="validar()"
            ></v-select>
           
            </div>
            </div>
        
        
        
         
            <button @click="continuar()" class="continuar" :disabled="!validado">Continuar</button>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </transition>
 
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      
      respuestas: [],

      opciones: [],
      preguntas: [],
      

      validado: false,

      display: false,
    };
  },
  watch: {},
  mounted(){
    this.display = true
    this.getPreguntas();
  },
  methods: {
    continuar() {

      this.$session.set('pri', this.convertirRespuestasToId());
      
      window.scrollTo(0, 0);
      this.display = false
    setTimeout(() => {  this.$router.push("/paso5"); }, 400);    
    },
    getPreguntas(){
       
    let data = {
            "tipo": "perfil"
        }
      const options = {
          method: 'POST',
          data,
          headers: { 'Content-Type': 'application/json'},
          url: process.env.VUE_APP_BACKURL + "/obtenerPreguntas",
        };
        axios(options)
        .then((result) => {
            
            this.preguntas = result.data;
            console.log(this.normalizarRespuestas())
           
        })
        .catch((error) => {
            console.log(error)
        })
    },
    normalizarRespuestas(){
      
      this.preguntas.forEach(pregunta => {
        let r = [];
        pregunta.opcion.forEach(opcion => {     
          r.push(opcion.descripcion)
        });     
        this.opciones.push(r)
      });
    },
    validar(){
        if(this.respuestas.length < this.preguntas.length){
          this.validado = false;   
        } else {
          this.validado = true;      
        }       
    },
    convertirRespuestasToId(){

      let index = 0
      let result = []
      this.respuestas.forEach(r => {
        
      this.preguntas[index].opcion.forEach(o => {
        
        if(o.descripcion == r){

            let x = []
            x[0] = o.pregunta_id
            x[1] = o.id
            result.push(x)
        
        }
      });
      index++   
      });      
      return result
    }
  },
};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
    font-family: 'Lato', sans-serif;
  }


.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center; 
  font-weight: 800;
}



.body {
  background-color: black;
}


h4 {
  line-height: 20px;
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 10px;
   margin-bottom: 10px;
}
.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}


.fields {
  margin-top: 25px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.fields::-webkit-scrollbar {
  width: 12px;
}

.fields::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
  
}



.fields::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}



.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 75px;
  margin-bottom: 20px;
  
}
.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

  

}
</style>
<template>
  <div>
    <link
      href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
      rel="stylesheet"
      type="text/css"
    />
    <div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo" />
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutLeft"
    >
      <v-row class="d-flex justify-center" v-if="display">
        <v-col cols="12" sm="12" md="6" lg="5" xl="4">
          <v-card
            elevation="5"
            class="maincard"
            style="background-color: #fbfbfb"
          >
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <h1>Documentación Patrimonial</h1>
                <div class="dot-div">
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                  <fa icon="circle" class="this-dot" />
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10" sm="7" md="10">
                <h4 style="margin-top: 50px">
                  Siendo requerida también la actualización de tu perfil
                  patrimonial, necesitamos que nos adjuntes documentación
                  patrimonial vigente.
                </h4>
                <h4>A modo de ejemplo: Recibo de sueldo, DDJJ
                  de Bienes Personales y/o Ganancias; Facturas, Certificación
                  Contable, Etc. (Sí aportaste esta documentación recientemente,
                  desestimá este punto/requerimiento)</h4>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-col cols="10" sm="7" md="6">
                <v-file-input
                  placeholder="Subir archivos"
                  outlined
                  dense
                  v-model="file"
                  style="position: relative; top: 0px"
                  prepend-inner-icon="mdi-folder-open"
                  prepend-icon=""
                  @change="cargarFile"
                  ref="inputFile"
                  accept=".pdf,.docx,.doc,.docm,.xml,.xlsx,.xlsm,.xls,.png,.gif,.jpg,.jpeg"
                ></v-file-input>

                <div class="file-container">
                  <div class="file-row" v-for="(file, index) in files" :key="file">
                    <p class="file-item">{{ file.name }}</p>

                    <v-dialog v-model="dialog[index]" persistent  max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          style="position: relative; top: -5px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <fa
                            icon="fa-solid fa-trash-can"
                            class="remove"
                            
                          />
                        </v-btn>
                      </template>
                      <v-card>
                        <p class="eliminar-text">¿Desea eliminar el archivo?</p>
                        <div class="eliminar-btn-container">
                          <v-btn
                            color="#0c5297"
                            text
                            @click="closeDialog(index)"
                            class="eliminar-btn"
                          >
                            No
                          </v-btn>
                          <v-btn
                            color="#0c5297"
                            text
                            @click="eliminarFile(file.name, index)"
                            class="eliminar-btn"
                          >
                            Sí
                          </v-btn>
                        </div>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>

                <div class="tyc">
                  <v-container fluid class="d-flex justify-center" style="position:relative; top: -30px">
                    <v-checkbox v-model="tycAceptado" style="font-size: 15px">
                      <template v-slot:label>
                        <div style="font-size: 15px">
                          Acepto los
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                target="_blank"
                                @click.stop
                                @click="openmodal()"
                                v-on="on"
                                style="text-align: center"
                              >
                                Términos y Condiciones
                              </a>
                            </template>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-container>
                  <button
                    :disabled="!tycAceptado"
                    @click="continuar()"
                    class="continuar"
                  >
                    Enviar
                  </button>
                </div>

                <transition name="fade">
                  <div class="modal-fade" v-if="modaltyc">
                    <div class="modal-content-dni">
                      <button @click="closemodal()" class="cerrar-modal">
                        x
                      </button>

                      <div>
                        <v-row class="d-flex justify-center">
                          <v-col cols="11" sm="11" md="10">
                            <div class="rubro-container">
                              <span class="text-rubro">
                                Términos y condiciones
                              </span>
                            </div>

                            <div class="actividad-container">
                              <div
                                style="display: flex"
                                v-for="item in tyc"
                                :key="item"
                              >
                                <p class="item">{{ item }}</p>
                              </div>
                              <div
                                style="display: flex"
                                class="subtitle-container"
                                v-for="item in sublista23"
                                :key="item"
                              >
                                <p class="subitem">{{ item }}</p>
                              </div>
                              <div
                                style="display: flex"
                                v-for="item in tyc2"
                                :key="item"
                              >
                                <p class="item">{{ item }}</p>
                              </div>
                              <div
                                style="display: flex"
                                class="subtitle-container"
                                v-for="item in sublista26"
                                :key="item"
                              >
                                <p class="subitem">{{ item }}</p>
                              </div>
                              <div
                                style="display: flex"
                                v-for="item in tyc3"
                                :key="item"
                              >
                                <p class="item">{{ item }}</p>
                              </div>
                              <div
                                class="d-flex justify-center"
                                style="width: 100%"
                              >
                                <h5 class="tyc-subtitle">
                                  Mandato de Administración Discrecional Parcial
                                </h5>
                              </div>
                              <div
                                style="display: flex"
                                v-for="item in mandato"
                                :key="item"
                              >
                                <p class="item">{{ item }}</p>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </transition>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      file: null,
      tycAceptado: false,
      modaltyc: false,
      files: [],
      dialog: [],

      tyc: [
      "El/los firmantes al pie de la presente solicita/n la apertura o actualización de una cuenta comitente, quedando sujeta a aprobación por parte de Allaria S.A. La cuenta comitente operará bajo las siguientes condiciones:",
        "1) Las firmas, domicilios y demás datos que se encuentran en este registro de clientes se consideran válidos y vigentes hasta tanto Allaria S.A. reciba notificación en forma fehaciente de la modificación requerida, aún cuando la misma haya sido publicada y registrada en los organismos pertinentes. Asimismo, el cliente declara bajo juramento que la información detallada en los formularios que conforman el legajo, es fiel y completa expresión de la verdad.",
        "2) Allaria S.A. en su actuación como Agente registrado podrá realizar aquellas operaciones detalladas en los Capítulos IV y V del Título VI de las Normas N.T. 2013 y modificatorias, en Mercados autorizados por la Comisión Nacional de Valores. Dichas operaciones, su liquidación, tenencias y acreencias serán a través del depósito colectivo de los títulos valores del comitente en el depositante del Agente de Caja de Valores. Sin perjuicio de esto, el cliente podrá administrar sus tenencias directamente en Caja de Valores.",
        "3) Las operaciones por tratativas directas o negociación bilateral no garantizada bajo las condiciones establecidas por los Mercados en que intervenga Allaria S.A. serán conforme lo establecido en el Titulo VI Capítulo V Artículo 3 del texto de las Normas N.T. 2013 y modificatorias.",
        "4) En lo que respecta a operaciones del exterior, las mismas se ajustarán a lo establecido en las Normas N.T. 2013 y modificatorias. Allaria S.A. podrá realizar operaciones de compra y/o venta de instrumentos financieros en el exterior por cuentas de titularidad de la sociedad y a pedido del cliente, siempre que cuenten con autorización por parte de Comisiones de Valores u otros organismos de control extranjeros, que correspondan a países incluidos dentro del listado de países cooperadores previstos en el artículo 2º del inciso b) del Decreto Nº 589/2013, para ser comercializados en esas jurisdicciones a inversores, acorde al perfil de riesgo del cliente. Dichas operaciones no constituyen una orden para el Agente, en calidad de tal, sino como mandatario del ordenante. El cliente declara conocer que Allaria S.A. ejecutará dichas órdenes a través de un broker del exterior a elección del Agente, y que la operación resultante no se encuentra garantizada por BYMA ni por ningún otro Mercado de los autorizados por la Comisión Nacional de Valores, y que la misma será cumplida por terceros no sujetos al control de la mencionada Comisión. Es por este motivo que estas operaciones son reservadas en forma exclusiva a Inversores Calificados. Por último, el cliente autoriza a Allaria S.A., a mantener en custodia los títulos de su propiedad en cuentas del exterior registradas a nombre de la firma, con el objeto de agilizar la operatoria de referencia.",
        "5) El cliente deberá identificar el riesgo que desea afrontar en el Perfil de Riesgo del Inversor. Cuando la operatoria a realizarse en la cuenta comitente no esté incluida en el nivel de riesgo elegido, Allaria S.A. deberá contar con previa autorización por escrito del cliente o su convalidación fehaciente. Asimismo, el cliente declara conocer los riesgos inherentes de mercado, entendiendo que los mismos comprenden: riesgo de variación de precios de los activos, riesgo de liquidez, riesgo de crédito del emisor (privado o público) o de incumplimiento de pago, riesgo cambiario o de moneda y riesgo soberano, incluyendo el riesgo de cambios regulatorios o fiscales.",
        "6) El cliente manifiesta que las instrucciones que imparta a Allaria S.A., se emitirán cumpliendo alguno de los siguientes medios o modalidades de contacto: presencial, internet, teléfono, correo electrónico, redes privadas de comunicación y cualquier otro que pudiera surgir en el futuro. La instrucción impartida o la confirmación específica tendrá validez diaria. Asimismo, el cliente podrá otorgar a Allaria un Mandato de Administración Discrecional Total o Parcial para que actúe en su nombre administrando sus inversiones y tenencias.",
        "7) El cliente conserva la facultad de otorgar y/o revocar el Mandato de Administración Discrecional Total o Parcial que otorgue voluntariamente al Agente para que actúe en su nombre. La ausencia de dicho Mandato hace presumir, salvo prueba en contrario, que las operaciones realizadas por el Agente no contaron con el consentimiento del cliente. En caso de no suscribir dicho Mandato, el cliente deberá elegir alguna de las modalidades detalladas en el punto anterior para solicitar sus operaciones quedando a definición de Allaria S.A. si el medio elegido por el cliente le otorga la fuerza probatoria suficiente para su ejecución.",
        "8) En el caso de que la cuenta comitente arroje saldos deudores, tanto en pesos como en otras monedas, según lo establecido en el Titulo VII Capitulo II Artículo 11 del texto de las Normas N.T. 2013 y modificatorias, se autoriza a Allaria S.A. a cobrar recargos sobre los saldos monetarios descubiertos, utilizando como referencia la tasa de caución tomadora para el plazo de 7 días, más impuestos y gastos inherentes a dicha operatoria. Esta autorización también tendrá vigencia en el caso de que dichos saldos deudores se originen en gastos debitados por el Agente incluidos en la Tabla de Aranceles y Comisiones. Sin perjuicio de esto, Allaria podrá ejecutar en forma compulsiva activos de la cartera del cliente deudor, hasta la concurrencia de la deuda.",
        "9) Los saldos líquidos provenientes de operaciones instruidas por el cliente en forma específica, sólo serán reinvertidos a su solicitud y se autoriza al Agente a que los mantenga en el giro operativo de su actividad como Agente. En caso de ser solicitados en forma expresa por el cliente, los fondos líquidos serán transferidos a las cuentas bancarias declaradas por el cliente. En el marco de la actuación conforme el mandato de Administración Discrecional Total o Parcial y sin perjuicio de lo establecido en el Titulo VII, Capitulo II, Artículo 4 del texto de las Normas N.T. 2013, el administrador idóneo, invertirá los saldos líquidos cuando oportunamente lo considere, siempre priorizando el beneficio del cliente y dando cumplimiento a la estrategia elegida para dicha cuenta.", 
        "10) El cliente tiene derecho a solicitar el retiro de los saldos monetarios disponibles en su cuenta comitente, incluidas acreencias depositadas en su subcuenta comitente abierta en el Agente de Deposito Colectivo, atendiendo a las normas vigentes en relación a los retiros de fondos, y en los días y horarios en que Allaria S.A. preste sus servicios. El cliente podrá completar el Instructivo Permanente para transferencias de fondos a cuentas de misma titularidad, en cuyo caso, deberá solicitar las mismas a través de alguna de las direcciones de mail declaradas en la cuenta o a través de la página web del Agente.",
        "11) Allaria S.A. presta el servicio de cobro de dividendos, rentas, amortizaciones, etc., de títulos valores como así también suscripciones, prorrateos y en general todo tipo de servicios que hacen a la actividad, sin responsabilidad alguna en caso de omisión por parte del Agente pagador de los servicios antes mencionados.",
        "12) El cliente toma conocimiento que podrá consultar a Allaria S.A. si la operatoria realizada en su cuenta comitente tiene garantía de algún Mercado autorizado por la Comisión Nacional de Valores o Cámara Compensadora en su caso. Dicha situación estará expresada en el boleto de la operación concertada.",
        "13) Allaria S.A. no asegura rendimientos de ningún tipo y cuantía ya que las inversiones están sujetas a las fluctuaciones de las condiciones y precios de mercado. Asimismo, en ningún caso, se garantiza la protección del valor aportado a la cuenta comitente.",
        "14) En virtud del Régimen Informativo con Clientes, según el Título VII, Capítulo II, Artículo 17 de las Normas NT 2013 de la Comisión Nacional de Valores, el agente enviará un mail de notificación automático a la dirección de correo electrónico declarada por el/ los firmante/s al finalizar cada día en que se produzcan operaciones en la cuenta comitente. Dichas operaciones se podrán visualizar, sin límite de día y horario, ingresando con un nombre de usuario y clave personal en la página Web oficial de Allaria S.A. El cliente podrá solicitar dicha clave desde el sitio oficial del Agente cuando lo desee. Cualquier correo electrónico declarado por el cliente es vinculante a los efectos del envío de la documentación.",
        "15) El cliente declara bajo juramento, a los efectos de dar cumplimiento a la normativa vigente en materia de Prevención del Lavado de Dinero y Financiación del Terrorismo dictada por la UIF (Unidad de Información Financiera) y la CNV (Comisión Nacional de Valores), que la totalidad de los fondos y valores utilizados para realizar transacciones a través de Allaria S.A. son de origen lícito. Asimismo, el cliente se compromete a aportar toda la información y/o documentación que en el futuro le sea requerida por Allaria S.A. en cumplimiento de la citada normativa.",
        "16) El cliente autoriza a Allaria S.A. a entregar a las entidades financieras intervinientes en la liquidación de las operaciones cursadas por el mismo y a los organismos de contralor, tanto locales como internacionales, documentación requerida por ellos en atención al cumplimiento de la normativa vigente, en materia de Prevención del Lavado de Dinero y Financiación del Terrorismo o bien a regulaciones específicas que deba cumplimentar el Agente. La documentación a entregar será según cada caso, la aportada por el cliente a Allaria S.A., en virtud de esta solicitud de apertura de cuenta comitente o bien con posterioridad a ella en el transcurso de la relación comercial.",
        "17) En caso de que el cliente solicite el cierre de su cuenta comitente, deberá notificar a Allaria S.A. en forma expresa. Sin perjuicio de ello Allaria S.A. se reserva el derecho de proceder al cierre de la cuenta ante el incumplimiento por parte del cliente de la normativa de Prevención del Lavado de Dinero y Financiación del Terrorismo, así como por asuntos de índole comercial en los que el Agente crea conveniente discontinuar el vínculo con el cliente. En ambos casos Allaria S.A. notificará al cliente el cierre de su cuenta. Asimismo, Allaria S.A. podrá, sin notificar al cliente, cerrar cuentas inactivas y/o sin posición. Para proceder al cierre de la cuenta, la misma no deberá registrar saldos monetarios ni tenencias de títulos valores. Para ello, Allaria S.A. se limitará a liquidar operaciones pendientes y transferirá al cliente tenencias y saldos resultantes en su cuenta comitente sin que esta decisión otorgue al mismo derecho de exigir compensación alguna.",
        "18) El cliente manifiesta conocer los riesgos que asume ante posibles incumplimientos por parte del Agente en materia de custodia, operatoria y liquidación de operaciones, así como también los organismos a los cuales deberá dirigirse para efectuar el reclamo pertinente.",
        "19) El cliente durante todo el vínculo comercial con Allaria S.A., podrá acercar consultas o inquietudes al Agente a través del personal idóneo con el que se relacione en forma habitual. Asimismo, podrá formular eventuales reclamos, directamente al Responsable de Relaciones con el Público, a la dirección de mail responsable.cliente@allaria.com.ar. El nombre de dicho funcionario estará disponible en la página web, y el cliente podrá solicitar una reunión en forma personal si lo considera conveniente.",
        "20) El cliente se notifica que los costos intervinientes vigentes, Tabla de Aranceles y Comisiones y Derechos de Mercado, están disponibles para consulta, en el sitio oficial de la compañía www.allaria.com.ar.",
        "21) Allaria S.A. en su carácter de Agente registrado en la Comisión Nacional de Valores, se compromete a cumplir con lo establecido en el Título VII, Capítulo II, Artículo 16 del Texto de Normas N.T. 2013 referido a las obligaciones del Agente.",
        "22) El cliente autoriza expresamente a Allaria S.A. a compartir su legajo de cliente, así como la información y documentación en él contenido, con aquellos Agentes Registrados que sean Sujetos Obligados ante UIF que formen parte del Grupo ó con los que Allaria S.A. haya celebrado un acuerdo a tales efectos.",
        "23) En caso de que Allaria S.A. celebre acuerdos con RENAPER u organismos similares que ofrecen el servicio de validación de datos personales, el cliente, en su carácter de titular de los datos, presta consentimiento para que “Allaria S.A” en su carácter de cesionario confronte sus datos personales con la base de datos del RENAPER, conforme a las siguientes especificaciones:",
      ],
      sublista23: [
      "a) el presente consentimiento para el tratamiento de los datos personales del cliente, alcanza a los incluidos en su DNI en confronte con lo que informa el web service del RENAPER,",
        "b) los datos serán tratados con la finalidad de validar la identidad del cliente y verificar la vigencia de su DNI para Allaria S.A., por lo que el cliente autoriza su guardado.",
        "c) Los datos son facilitados con carácter obligatorio, por cuanto es imprescindible identificar fehacientemente al titular, para asegurar el correcto proceso de identificación.",
        "d) El titular de los datos podrá ejercer los derechos de acceso, rectificación y supresión de sus datos en cualquier momento y a su sola solicitud ante el RENAPER.",
        "e) En cumplimiento de la Resolución AAIP Nº 14/2018, informamos que la Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.", 
      ],
      tyc2: [
        "24) El cliente manifiesta que suscribió convenio con el siguiente AAGI: ______________________ Asimismo, el cliente se compromete a informar en forma fehaciente a Allaria en caso que la información aquí declarada sufra modificaciones",
        "25) El cliente pondrá a disposición de Allaria SA los saldos necesarios para cumplimentar con las garantías y reposiciones exigidas por los mercados intervinientes, ya sea en moneda nacional y/o extranjera y/o Valores Negociables. Asimismo, Allaria SA podrá requerir al cliente, cuando así lo considere necesario, la integración de garantías adicionales a las solicitadas por el Mercado. En caso que el cliente se negara, Allaria no estará obligado a realizar la operación en cuestión.",
        "26) Se podrá acceder a información y normativa relativa a la actividad del Agente en las siguientes páginas web:"
      ],
      sublista26: [
        "- Allaria S.A. (www.allaria.com.ar)",
        "- Comisión Nacional de Valores (www.cnv.gob.ar)",
        "- Mercado de Valores de Buenos Aires  (www.merval.sba.com.ar)",
        "- Bolsas y Mercados Argentinos (www.byma.com.ar)",
        "- Caja de Valores S.A. (www.cajval.sba.com.ar)",
        "- Bolsa de Comercio de Buenos Aires  (www.bcba.sba.com.ar)",
        "- Ministerio  de Economía y Finanzas Públicas (www.mecon.gov.ar)",
        "- Banco Central de la República Argentina (www.bcra.gov.ar)",
      ],

      tyc3: [
        "27) El cliente declara conocer y aceptar las siguientes Circulares del Mercado Argentino de Valores S.A. (MAV) a saber: N° 808 del 17/08/2010, referente a la negociación de cheques de pago diferido patrocinado y avalado, N° 820 del 14/03/2013, referente a la negociación directa de cheques de pago diferido y Circular del 04/12/2019 y modificatorias, referente a la negociación de pagaré bursátil. Asimismo, declara conocer los riesgos de los distintos tipos de operaciones detalladas en las mismas, y en caso de solicitar una operación de este tipo, se compromete a informar a ALLARIA el segmento donde se opere. ALLARIA y MAV no asumen responsabilidad alguna respecto del crédito o solvencia de las partes.",
        "28) La relación entre las partes se regirá por la Ley de Mercado de Capitales Nº 26.831, el Decreto 1023/11, el texto de Normas N.T. 2013, las normas específicas de los Mercados autorizados por la Comisión Nacional de Valores en que opere el Agente y todas aquellas que sean dictadas en el futuro. Ante diferencias de criterios, el cliente y Allaria S.A. se someterán a la jurisdicción de los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires."
      ],
      mandato: [
        "Declaro que las operaciones en mi cuenta comitente serán instruidas en forma específica por mí por alguno de los medios o modalidades de contacto habilitados por Allaria a tal fin -presencial, internet, teléfono, correo electrónico, redes privadas de comunicación y cualquier otro que pudiera surgir en el futuro. Serán válidas como instrucciones específicas aquellas que indiquen al menos los siguientes parámetros: especie/instrumento, cantidad, pecio o rango de precio, incluida la referencia a “precio de mercado”. También será considerada como instrucción precisa una confirmación específica posterior, otorgada dentro de las 24 hs. de notificada la operación.", 
        "Asimismo, autorizo al personal idóneo de Allaria S.A. a que ejecute para mi cuenta comitente en mi nombre e interés, decisiones de inversión y/o tenencia cuando advierta una oportunidad que encuadre dentro del perfil de riesgo elegido por mí suscripto en oportunidad de la apertura de la cuenta comitente o de actualización de legajo. Este Mandato comprende la posibilidad de que el Agente actué sin necesidad de que yo imparta orden o instrucción específica e individual o consentimiento previo, y se ajusta a las limitaciones que las normas vigentes prevén para este tipo de Administración. Asimismo, es extensible a todas las operaciones vigentes, o aquellas que puedan surgir en el futuro, reguladas o fiscalizadas por los mercados registrados en la Comisión Nacional de Valores, y acordes al Perfil de Riesgo del Inversor por mi suscripto en oportunidad de Apertura o Actualización de cuenta comitente.",
        "Dejo expresa constancia que no habilito a Allaria S.A. a efectuar retiros de títulos valores, órdenes de pago o débito de títulos valores sobre mi cuenta comitente, que no se correspondan con la liquidación de las operaciones y acreencias, ni con sus costos operativos.",
        "Tengo pleno conocimiento que Allaria S.A. no asegura rendimientos de ningún tipo y cuantía sobre las inversiones incluidas en el presente Mandato, ya que las mismas están sujetas a las fluctuaciones de las condiciones y de precios de mercado. Asimismo, en ningún caso se me garantiza la protección del valor aportado a la cuenta comitente.",
        "Me notifico en este acto que, en virtud del Régimen Informativo con Clientes, según el Título VII Capítulo II Artículo 17 de las Normas NT 2013 y modificatorias, el Agente enviará un mail automático a la/s dirección/es de correo electrónico declarada/s por mí en la Ficha de Cuenta Comitente al finalizar cada día en que se produzcan operaciones en mi cuenta, con el detalle de dichas operaciones. Además, las operaciones las podré visualizar, sin límite de día y horario, ingresando con un nombre de usuario y clave personal en la página Web oficial de Allaria S.A.; que podré solicitar desde el sitio oficial del Agente cuando lo desee.",
        "Declaro conocer los costos detallados en la Tabla de Aranceles y Comisiones, y doy conformidad de sus posteriores actualizaciones, que serán debidamente publicadas en la página oficial de la compañía.",
        "Este mandato se encontrará vigente hasta tanto yo exprese su revocación y/o cancelación anticipada en forma escrita.",
      ],

      display: false,
    };
  },

  watch: {},
  mounted() {
    this.display = true;
  },
  methods: {
    continuar() {
      this.enviarDatos();
      this.enviarArchivos();

      window.scrollTo(0, 0);
      this.display = false;
      setTimeout(() => {
        //this.$router.push("/enviado");
      }, 400);
    },
    openmodal() {
      this.modaltyc = true;
      if (this.tycAceptado == true) {
        this.tycAceptado = false;
      } else {
        this.tycAceptado = true;
      }
    },
    closemodal() {
      this.modaltyc = false;
    },
    enviarDatos() {
      let data = {
        persona_id: this.$session.get("personaid"),
        nombre: this.$session.get("nombre"),
        apellido: this.$session.get("apellido"),
        dni: this.$session.get("dni"),
       // cuit: this.$session.get("cuit"),
       // cuil: this.$session.get("cuil"),
        ntramite: this.$session.get("ntramite"),
        fnacimiento: this.$session.get("fnacimiento"),
        lugarnacimiento: this.$session.get("lugarnacimiento"),
        nacionalidad: this.$session.get("nacionalidad"),
        estadocivil: this.$session.get("estadocivil"),
        actividades: this.$session.get("actividades"),
        calle: this.$session.get("calle"),
        altura: this.$session.get("altura"),
        piso: this.$session.get("piso"),
        departamento: this.$session.get("departamento"),
        localidad: this.$session.get("localidad"),
        codigopostal: this.$session.get("codigopostal"),
        telefono: this.$session.get("telefono"),
        correo: this.$session.get("correo"),
        correosadicionales: this.$session.get("correosadicionales"),
        pais: this.$session.get("pais"),
        provincia: this.$session.get("provincia"),
        pri: this.$session.get("pri"),
        riesgo: this.$session.get("riesgo"),
        patrimonio: this.$session.get("patrimonio"),
        pep: this.$session.get("pep"),
        causapep: this.$session.get("causapep"),
        residenciafiscal: this.$session.get("paisderesidenciafiscal"),
        nit: this.$session.get("nit"),
        emailRespuesta: this.$session.get("emailRespuesta"),
        sexo: this.$session.get('sexo'),
        antiguedad: this.$session.get('antiguedad'),
      };
      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_BACKURL + "/updatePersona",
      };
      axios(options)
        .then((result) => {

          var strResp = JSON.stringify(result);
          var respuesta = JSON.parse(strResp);

          if(respuesta.data.resultado == 'error'){
            let datos = {
              solicitud_id: this.$session.get("personaid"),
              tipo: "solicitud",
              respuesta: strResp,
              recibido: 0,
              error: JSON.stringify(respuesta.data.data.errorInfo)
            };
            axios.post(process.env.VUE_APP_BACKURL + '/guardarLogRespuesta', {datos}
                        ).then( response => {
                            console.log({response})
                            this.$router.push("/error");
                        }).catch(error => {
                            console.log({error});
            })

          }else{
            let datos = {
              solicitud_id: this.$session.get("personaid"),
              tipo: "solicitud",
              respuesta: 'exito',
              recibido: 1,
              error: 'exito'
            };
            axios.post(process.env.VUE_APP_BACKURL + '/guardarLogRespuesta', {datos}
                        ).then( response => {
                            console.log({response})
                            this.$router.push("/enviado");
                        }).catch(error => {
                            console.log({error});
                        })
          }


        })
        .catch((error) => {
          console.log("Entra en el error");
          console.log(error);
        });
    },
    enviarArchivos() {
      let data = new FormData();

      data.append("esdni", false);
      data.append("persona_id", this.$session.get("personaid"));

       this.files.forEach(file=>{
        data.append('documentacion[]', file);
      });

      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url:  process.env.VUE_APP_BACKURL + `/guardarAdjuntos`,
      };
      axios(options)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarFile() {
      if (this.file != null) {

        if(this.filevalidation(this.file)){
          this.files.push(this.file);
          this.dialog.push(false);
        }

        this.file = null;
        this.$refs.inputFile.reset();
        this.$refs.inputFile.blur();
      }
    },
    filevalidation(file){
      
      const allowedExtensions =  /(\.pdf|\.docx|\.doc|\.docm|\.xml|\.xlsx|\.xls|\.png|\.gif|\.jpg|\.jpeg)/
      
      if(allowedExtensions.test(file.name.toLowerCase())){
        return true;
      } else {
        return false;
      }

    },
    eliminarFile(filename, dialogIndex) {
      this.dialog.splice(dialogIndex,1)
      let index = 0;
      let found = -1;
      this.files.forEach((f) => {
        if (f.name == filename) {
          found = index;
        }
        index++;
      });

      if (found != -1) {
        this.files.splice(found, 1);
      }
    },
    closeDialog(dialogIndex){
      this.$set(this.dialog, dialogIndex, false)
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  font-family: "Lato", sans-serif;
}

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.eliminar-text {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.eliminar-btn {
  font-weight: 900;
}

.eliminar-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.actividad {
  /* width: 320px; */
  color: #000000;
  font-size: 14px;
  font-weight: 900;

  text-align: left;
}

.item {
  /* width: 320px; */
  color: #000000;
  font-size: 14px;
  font-weight: 900;

  text-align: left;
}

.subitem {
  /* width: 320px; */
  color: #000000;
  font-size: 12px;
  font-weight: 900;
  margin-left: 10px;
  text-align: left;
}

h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center;
  font-weight: 800;
}

.file-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.remove {
  color: #0c5297;
  justify-self: left;
  font-size: 18px;
}
.file-item {
  font-weight: 800;

  max-width: 80%;
}

.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  position: relative;
  top: -25px;
}

.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

h4 {
  line-height: 20px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.file {
  /* width: 320px; */
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.ejemplo {
  font-weight: bold;
  font-size: 13px;
  /* text-decoration-line: underline; */
  color: #0c5297;
  width: 300px;
  margin: 0 auto;
  text-align: left;
  position: relative;
  bottom: 25px;

  padding-bottom: 5px;
}

.ejemplo:hover {
  text-decoration: underline;
}

.ref-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto;
}

.ref {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  position: relative;
  top: 40px;
}

.ejemplo:hover {
  text-decoration: underline;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all 1s ease;
}
.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 1s ease;
}

.fade-standard-enter-from {
  opacity: 0;
}
.fade-standard-enter-to {
  opacity: 1;
}
.fade-standard-enter-active {
  transition: all 1s ease;
}
.fade-standard-leave-from {
  opacity: 1;
}
.fade-standard-leave-to {
  opacity: 0;
}
.fade-standard-leave-active {
  transition: all 1s ease;
}

.modal-fade-standard {
  width: 100%;
  height: 3000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  right: 50%;
  transform: translate(50%, 0);
  padding-top: 250px;
  position: fixed;
  top: 0px;
  left: 0px;
}

.actividad-container::-webkit-scrollbar {
  width: 12px;
}

.actividad-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.actividad-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.file-container::-webkit-scrollbar {
  width: 12px;
}

.file-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.file-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.rubro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.cerrar-modal {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 30px;
  padding-bottom: 4px;
  border-radius: 5px;
  border: none;

  position: sticky;
  right: 10px;
  top: 10px;
}

.text-rubro {
  width: 90%;
  color: #000000;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.modal-content-dni {
  width: 50%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  margin-top: 0px;
  background-color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 100px;
  left: 25%;
  text-align: right;
}

.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}

.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}

.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.tyc {
  

  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 80px;
  margin-top: 30px;
  
  margin-bottom: 20px;
}
.file-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex--;
  height: 200px;
  
  overflow-y: auto;
  overflow-x: hidden;
}

.actividad-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 50px;
}

.actividad-container::-webkit-scrollbar {
  width: 12px;
}

.actividad-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.actividad-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

@media (max-width: 1340px) {
  .tyc {
    
  }
}

@media (max-width: 1260px) {
  .tyc {
    
  }
  .continuar {
    margin-bottom: 0px;
  }
}

@media (max-width: 960px) {
  .maincard {
    margin-top: 0%;
  }

  .modal-content-dni {
    width: 90%;
    position: fixed;
    top: 30px;
    left: 2%;
  }
  .tyc {
    
  }

  h1 {
    font-size: 30px;
  }
}
</style>
export class RegexHandler {


    static  validatePhoneNumber(number){

        
        var pattern =  /^(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)[0-9\-+/ ]{8,}$/;
        return pattern.test(number.trim())

    }

}


export default RegexHandler;
<template>
  <div> 
      <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
      <div class="header">

      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="fade-enter"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">
    <v-col cols="12" sm="12" md="6" lg="5" xl="4" >
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb" >
        <v-row class="d-flex justify-center" v-if="expirado">
          <v-col cols="10">
            <h1>Hola {{ nombre }}</h1>
            <p class="subtitulo">
              Por favor, verificá que tus datos sean correctos y respondé las
              preguntas a continuación:
            </p>
          </v-col>
        </v-row>
        <div v-if="cargado">
        <v-row class="d-flex justify-center" v-if="!expirado">
          <v-col cols="10">
            <h1>Link expirado </h1>
            <p class="subtitulo">
              Por favor, comuníquese con el equipo de Legales humanaslegales@allaria.com.ar
            </p>
          </v-col>
        </v-row>
        </div>
        <div v-if="!cargado">
          <transition class="loadingcomponent-container-standard" name="fade-standard">
            <div class="modal-fade-standard" v-if="loading">
                <div class="modal-content-completar-standard">
                    <div class="loading-container-standard">
                        <img class="loading-standard" :src="'/loading.gif'" alt="">
                    </div>
                </div>
            </div> 
          </transition>
        </div>




        <v-row class="d-flex justify-center" v-if="expirado">
          <v-col cols="10" sm="7" md="6">
            <div class="topmargin"></div>
            <div class="fields">

            <v-text-field
              label="Nombre"
              hide-details="auto"
              v-model="nombre"
              outlined
              dense
              
              class="firsttxtfield"
              style="margin-top: 20px; margin-bottom: 20x"
              @change="validar()"            
              @keyup="validar()"
            ></v-text-field>
            <v-text-field
              label="Apellido"
             
              hide-details="auto"
              v-model="apellido"
              outlined
              dense
              style="margin-top: 25px; margin-bottom: 20px"
              @change="validar()"            
              @keyup="validar()"
            ></v-text-field>
            <v-select
                :items="sexos"
                label="Sexo"
                outlined
                hide-details="auto"
                dense
                v-model="sexoselected"
                class=""
                @change="validar()" 
                style="margin-top: 25px"
              ></v-select>
            <v-text-field
              label="DNI"
              
              hide-details="auto"
              v-model="dni"
              outlined
              disabled
              dense
              style="margin-top: 26px; margin-bottom: 25px"
            ></v-text-field>


            
            <v-text-field
              label="Número de Trámite"
              hide-details="auto"
              v-model="ntramite"
              outlined
            :rules="rulesTramite.ntramite"
             @change="validar()"            
              @keyup="validar()"
              dense
              style="margin-top: 26px; margin-bottom: 0px"
            >
            </v-text-field>



            <button style="">
              <p class="ejemplo" @click="cerrarModalTramite()">*¿Dónde se encuentra mi número de trámite?</p>
            </button>

            <v-file-input
                  placeholder="DNI - Frente"
                  outlined
                  dense
                  v-model="dnifrente"
                  style="position: relative; top: 0px"
                  prepend-inner-icon="mdi-folder-open"
                  prepend-icon=""
                  @change="validar()"
                  ref="inputFrente"
                  
                  
                ></v-file-input>
                <v-file-input
                  placeholder="DNI - Dorso"
                  outlined
                  dense
                  v-model="dnidorso"
                  style="position: relative; top: 0px"
                  prepend-inner-icon="mdi-folder-open"
                  prepend-icon=""
                  @change="validar()"
                  ref="inputDorso"
                ></v-file-input>


            </div>
            <button @click="continuar()" class="continuar" :disabled="!validado">Continuar</button>
            <transition name="fade">
              <div class="modal-fade" v-if="modalejemplo">
                <div class="modal-content-dni">
                  <button @click="cerrarModalTramite()" class="cerrar-modal">
                      x
                    </button>
                    <div class="dni-container">
                     <img alt="Allaroa" src="../assets/DNI-ALLARIA-Frente.jpg" class="dni">
                     <img alt="Allaroa" src="../assets/DNI-ALLARIA-Dorso.jpg" class="dni">
                    </div>                           
                </div>
              </div>
            </transition>
            <transition class="loadingcomponent-container-standard" name="fade-standard">
        <div class="modal-fade-standard" v-if="loading">
            <div class="modal-content-completar-standard">
                <div class="loading-container-standard">
                    <img class="loading-standard" :src="'/loading.gif'" alt="">
                </div>
            </div>
        </div> 
    </transition>
          </v-col>
        </v-row>   
      </v-card>
    </v-col>
  </v-row>
       </transition>
       
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  data() {
    return {
      nombre: "",
      apellido: "",
      dni: "",
      ntramite: "",
      sexos: ['Masculino', 'Femenino'],
      correo: 2,
      dnifrente: null,
      dnidorso: null,
      validado: true,
      modalejemplo: false,
      sexoselected: null,
      persona: null,
      cargado: false,

      display: false,

      expirado: false,

      status: null,
      renaperrespondido: false,

      loading: true,

      rules: [(value) => !!value || ""],
      rulesTramite: {
        ntramite: [val => Number(val) > 0 || 'El valor es requerido y debe ser numérico',
                   val => Number.isInteger(Number(val)) || 'El valor debe ser numérico',
                   val => ( val && val.length < 12 ) || 'El número de trámite debe ser de 11 dígitos'
                   ]
      },


    };
  },
  name: "Home",
  components: {},
  methods: {
    continuar() {
      

      let sexo = 'M'
      if(this.sexoselected == 'Femenino'){
        sexo = 'F'
      }


      this.$session.set('nombre', this.nombre);
      this.$session.set('apellido', this.apellido);
      this.$session.set('dni', this.dni);
      this.$session.set('ntramite', this.ntramite);
      this.$session.set('sexo', sexo);


      this.loading = true;
      this.validado = false;
      this.enviarArchivos();
      this.validarRenaper();
      
      // para esperar la respuesta de renaper antes de continuar a la siguiente pantalla se espera el archivo 
      
  // this.$router.push("/paso1");
  

      
    },
    validarRenaper() {
      let sexo = 'M'
      if(this.sexoselected == 'Femenino'){
        sexo = 'F'
      }
      let data = {
        number: this.dni,
        order: this.ntramite,
        gender: sexo,
        idPersona: this.persona.datos.original[0].id
      };
      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_BACKURL + "/comprobacionRenaper",
      };
      axios(options)
        .then((result) => {
          console.log(result.data);
          this.status = result.data;
          this.getRenaperInfo();
          window.scrollTo(0, 0);
          this.display = false 
          setTimeout(() => {  this.$router.push("/paso1"); }, 400);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validar(){

      if(this.dnifrente != null){

        if(!this.filevalidation(this.dnifrente)){
          this.dnifrente = null;
          this.$refs.inputFrente.reset();
          this.$refs.inputFrente.blur();
        }
      }

      if(this.dnidorso != null){
        if(!this.filevalidation(this.dnidorso)){
          this.dnidorso = null;
          this.$refs.inputDorso.reset();
          this.$refs.inputDorso.blur();
        }
      }

      if(this.nombre == null 
        || this.nombre == '' 
        || this.apellido == null 
        || this.apellido == '' 
        || this.ntramite == null 
        || this.ntramite == ""  
        || this.sexoselected == null 
        || this.ntramite == '' 
        || this.dnidorso == null 
        || this.dnifrente == null

        || isNaN(this.ntramite) == true

      ){
        this.validado = false;
      } else {
        this.validado = true;
      }

    },
    filevalidation(file){
      
      const allowedExtensions =  /(\.pdf|\.docx|\.doc|\.docm|\.xml|\.xlsx|\.xls|\.png|\.gif|\.jpg|\.jpeg)/

      if(allowedExtensions.test(file.name.toLowerCase())){
        return true;
      } else {
        return false;
      }

    },
    cargarPersona(){
      
       let data = {
         id: this.$route.params.id,
       };
       
      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_BACKURL + "/actualizarSolicitud",
      };
      axios(options)
        .then((result) => {
          this.persona = result.data; 
          this.chequearExpirado();
          this.setearPersona();
          this.cargado = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.expirado = false
          this.cargado = true;
          this.loading = false;
        });

    },
    setearPersona(){
      // POR TESTEO ID PROVINCIA E ID PAIS SE HARDCODEA

    // this paso
    this.nombre = this.persona.datos.original[0].persona.nombres
    this.apellido = this.persona.datos.original[0].persona.apellidos
    this.dni = this.persona.datos.original[0].persona.dni
    
   
    

    // paso 1     
    this.$session.set('fnacimiento',  this.persona.datos.original[0].persona.fechaNacimiento);
    this.$session.set('nacionalidad',  this.persona.datos.original[0].persona.nacionalidad_id);
    this.$session.set('lugarnacimiento',  this.persona.datos.original[0].persona.lugarNacimiento);
    
    // paso 2 (el resto de los campos vienen de la validacion de renaper)
    

     this.$session.set('correo', this.persona.datos.original[0].persona.email)
    this.$session.set('correosadicionales',  this.persona.datos.original[0].persona.emailAdicional);
    this.$session.set('telefono',  this.persona.datos.original[0].persona.telefonoParticular);


    // paso 7
    this.$session.set('personaid',  this.persona.datos.original[0].id);
    this.$session.set('emailRespuesta',  this.persona.emailRespuesta);
    
    
  },
  chequearExpirado(){
    if(this.persona.datos.original[0].estado_id == null || this.persona.datos.original[0].estado_id == 4 || this.persona.datos.original[0].estado_id == 3){
        this.expirado = false
    } else {
      this.expirado = true
    }
  },
  cerrarModalTramite() {
      if (this.modalejemplo == true) {
        this.modalejemplo = false;
      } else {
        this.modalejemplo = true;
      }
    },
    enviarArchivos() {

      let data = new FormData();
      let documentacion = [];
      documentacion.push(this.dnifrente);
      documentacion.push(this.dnidorso);
      
      documentacion.forEach(file=>{
        data.append('documentacion[]', file);
      });

      data.append("esdni", true);
      data.append("persona_id", this.$session.get("personaid"));

      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url:  process.env.VUE_APP_BACKURL + `/guardarAdjuntos`,
      };
      axios(options)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRenaperInfo(){
      if(this.status.renaperMensaje != "no encontró información"){
        
    
        if(this.status.domicilioRenaperPais_id == null){
            this.$session.set('pais',  -1);
         } else {
           this.$session.set('pais',  this.status.domicilioRenaperPais_id);
         }
        if(this.status.domicilioRenaperProvincia_id == null){
         this.$session.set('provincia', -1);
        } else {
         this.$session.set('provincia', this.status.domicilioRenaperProvincia_id);
        }

        this.$session.set('calle', this.status.domicilioRenaperCalle);
        this.$session.set('altura', this.status.domicilioRenaperNumero);
        this.$session.set('piso', this.status.domicilioRenaperPiso);
        this.$session.set('departamento', this.status.domicilioRenaperDepartamento );
        this.$session.set('localidad', this.status.domicilioRenaperLocalidad );
        this.$session.set('codigopostal', this.status.domicilioRenaperCP );
    

      } else {

         if(this.status.domicilioEscoPais_id == null){
            this.$session.set('pais',  -1);
         } else {
           this.$session.set('pais',  this.status.domicilioEscoPais_id);
         }
        if(this.status.domicilioEscoProvincia_id == null){
         this.$session.set('provincia', -1);
        } else {
         this.$session.set('provincia', this.status.domicilioEscoProvincia_id);
        }

        this.$session.set('calle', this.status.domicilioEscoCalle);
        this.$session.set('altura', this.status.domicilioEscoNumero);
        this.$session.set('piso', this.status.domicilioEscoPiso);
        this.$session.set('departamento', this.status.domicilioEscoDepartamento );
        this.$session.set('localidad', this.status.domicilioEscoLocalidad );
        this.$session.set('codigopostal', this.status.domicilioEscoCP );


      }
    }
  },
  mounted() {
    this.display = true
    this.loading = true
    this.validar();
    this.cargarPersona();
  },
  
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  font-family: "Lato", sans-serif; 
}

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.cerrar-modal {
    background-color: #0c5297;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 30px;
    padding-bottom: 4px;
    border-radius: 5px;
    border: none;

    position: sticky;
    right: 10px;
    top: 10px;
   
  }

.fade-standard-enter-from{
    opacity: 0;
}
.fade-standard-enter-to{
    opacity: 1;
}
.fade-standard-enter-active{
    transition: all 1s ease;
}
.fade-standard-leave-from{
    opacity: 1;
}
.fade-standard-leave-to{
    opacity: 0;
}
.fade-standard-leave-active{
    transition: all 1s ease;
}

.modal-fade-standard{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0px;
    right: 50%;
    transform: translate(50%, 0);
    padding-top: 250px;
}


  .text-rubro{
    width: 320px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }

.dni-container{
  display:flex;
  justify-content: center;
  flex-direction: column;
  
  align-items: center;
  

  width: 100%;

  
}

.dni{
  height: 300px;
  width: 400px;
  
  
}

 .modal-content-dni {
    width: 25%;
    overflow-y: auto;
  overflow-x: hidden;
  height: 655px;
    margin-top: 0px; 
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 100px;
    left: 38%;
    text-align: right;
  }

  


.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}

.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.modal-content-dni::-webkit-scrollbar {
  width: 12px;
}


.modal-content-dni::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.modal-content-dni::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);

}


h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center;
  font-weight: 800;
}
.subtitulo {
  line-height: 20px;
  
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
   margin-bottom: 10px;
}

.topmargin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 125px;
  margin-bottom: 20px;
  
}

.ejemplo {
  font-weight: bold;
  font-size: 13px;
  /* text-decoration-line: underline; */
  color: #0c5297;
  width: 300px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 15px;

  padding-bottom: 0px;
}

.fields{
  margin-top: 50px;
  max-height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  }

.fields::-webkit-scrollbar {
  width: 12px;
}

.fields::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
  
}



.fields::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}



@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }
.modal-content-dni{
    width: 90%;
    position: fixed;
    top: 30px;
    left: 2%;
  }
 
}






@media (max-width: 357px) {
  h1 {
    font-size: 32px;
  
    text-align: center;
    font-weight: 800;
  }
   .subtitulo {
    text-align: center;
    margin-top: 1px;
    font-size: 12px;
  }
 
}


@media (max-width: 305px) {
  h1 {
    font-size: 28px;
    
    text-align: center;
    font-weight: 800;
  }

  .subtitulo {
    text-align: center;
    margin-top: 1px;
    font-size: 12px;
  }




}


  @media (max-width: 340px) {
  .dni{
    width: 300px;
    
    height: 200px;
    
  }

  .dni-container{
  
  

  min-height: 400px;


  
}

.modal-content-dni{
  height: 500px;
}


}



@media (max-width: 282px) {
  h1 {
    font-size: 34px;
   
    text-align: center;
    font-weight: 800;
  }

  .subtitulo {
    text-align: center;
    margin-top: 1px;
    font-size: 10px;
  }
 

}


.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar:disabled {
  background-color: #d0d0d0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
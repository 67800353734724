<template>
<div>


<link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
<div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    >
  <v-row class="d-flex justify-center" v-if="display">
    
    <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Perfil de Riesgo</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" sm="8" >
            <h4 style="position:relative; top:0px; margin-top:30px">
              El riesgo que asumís varía de acuerdo a los instrumentos que
              invertís.
            </h4>
            <h4 style="margin-top:15px">
              Por favor, seleccioná el tuyo:
            </h4>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="8" sm="4">
            <v-select 
              :items="opciones"
              outlined
              dense
              style="position:relative; top:10px;"
              v-model="riesgo"
              @change="actualizarlista"
              hide-details=""
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center" >
          <v-col cols="11" sm="11" md="10">
            <div class="rubro-container">
              <span class="text-rubro">
                Instrumentos disponibles según riesgo seleccionado:
              </span>
            </div>

            <div class="items-container">
              <div
                style="display: flex"
                v-for="instrumento in instrumentos"
                :key="instrumento.descripcion"
              >
                <li class="actividad">{{ instrumento.descripcion }}</li>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10" sm="7" md="6">
            <button @click="continuar()" class="continuar">Continuar</button>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  </transition>
  
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      listariesgos: ["Riesgo Bajo", "Riesgo Medio", "Riesgo Alto"],
      pregunta: null,
      opciones: null,
      riesgo: "Riesgo Bajo",
      instrumentos: [],
      instrumentosbajo: [
        { descripcion: "Compra y/o venta de Títulos Públicos Nacionales, Provinciales y Municipales (incluye Letras y Bonos)" },
        { descripcion: "Compra y/o venta de Letras del Banco Central de la República Argentina." },
        { descripcion: "Compra y/o venta de Obligaciones Negociables." },
        { descripcion: "Caución colocadora." },
        { descripcion: "Compra y/o venta de Cheques de pago diferido." },
        { descripcion: "Préstamo colocador." },
      ],
      instrumentosmedio: [
        {descripcion: "Compra y/o venta de Acciones ordinarias y preferidas."},
        {descripcion: "Colocaciones en Fondos Comunes de Inversión de Renta Variable."},
        {descripcion: "Compra y/o venta de acciones negociadas según mandato en mercados del exterior." },
        {descripcion: "Préstamo tomador." },
      ],
      instrumentosalto: [
        {descripcion: "Compra y/o venta de futuros a) de títulos públicos, b) de monedas, c) de títulos privados, d) de metales o productos agropecuarios." },
        {descripcion: "Compra y/o venta de Opciones de títulos privados y públicos." },
        {descripcion: "Caución tomadora y compra de activos de cualquier naturaleza con apalancamiento sobre garantía de dicho activo y/u otros" },
        {descripcion: "Préstamos tomador con Venta en Corto." },
        {descripcion: "Colocaciones en Fondos Comunes de Inversión cerrados." },
      ],
      display: false,
    };
  },

  watch: {},
  methods: {
    continuar() {


      this.$session.set('riesgo', this.convertirRespuestasToId());
      
  
      window.scrollTo(0, 0);
      this.display = false
    setTimeout(() => {  this.$router.push("/paso6"); }, 400);
    },
    actualizarlista() {
      if (this.riesgo == "Riesgo Bajo") {
        this.instrumentos = this.instrumentosbajo;
      } else if (this.riesgo == "Riesgo Medio") {
        this.instrumentos = this.instrumentosbajo.concat(
          this.instrumentosmedio
        );
      } else if (this.riesgo == "Riesgo Alto") {
        this.instrumentos = this.instrumentosbajo.concat(
          this.instrumentosmedio
        );
        this.instrumentos = this.instrumentos.concat(this.instrumentosalto);
      }
    },
    getPreguntas() {
      let data = {
        tipo: "riesgos",
      };
      const options = {
        method: "POST",
        data,
        headers: { "Content-Type": "application/json" },
        url: process.env.VUE_APP_BACKURL + "/obtenerPreguntas",
      };
      axios(options)
        .then((result) => {
          this.pregunta = result.data;
           this.normalizarRespuestas();
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    normalizarRespuestas() {
        let r = []
        this.pregunta[0].opcion.forEach((opcion) => {
          r.push(opcion.descripcion);
        });
        this.opciones = r
      
    },
    convertirRespuestasToId() {
      
      let result = [];
    
        this.pregunta[0].opcion.forEach((o) => {
          if (o.descripcion == this.riesgo) {
            
            result[0] = o.pregunta_id;
            result[1] = o.id;
            
          }
        });
        
     
      return result;
    },
    getRiesgoId(){
       if(this.riesgo == "Riesgo Bajo"){
          return 0
       } else if (this.riesgo == "Riesgo Medio"){
         return 1
       } else if (this.riesgo == "Riesgo Alto"){
         return 2
       } else {
         return -1
       }
    }
  },
  computed: {},
  mounted() {
    this.display = true
    this.actualizarlista();
    this.getPreguntas();
  },
};
</script>

<style scoped>


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

*{
    font-family: 'Lato', sans-serif;
  }


.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}



.body {
  background-color: black;
}

h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center; 
  font-weight: 800;
}


h4 {
  line-height: 20px;
  
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 20px;
   margin-bottom: 0px;
}
.subtitulo {
  text-align: center;
  margin-top: 1px;
}
.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  top:0px
}
.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}
.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.rubro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
}

.text-rubro {
  width: 90%;
  color: #000000;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.rubroSearch {
  width: 300px;
  height: 45px;
  background: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 20px;
}

.items-container {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 270px;
  overflow-y: auto;
  
}

.actividad {
  /* width: 320px; */
  color: #000000;
  font-size: 11px;
  font-weight: 900;

  text-align: left;
}
.items-container::-webkit-scrollbar {
  width: 12px;
}

.items-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.items-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

  
}

</style>
<template>

  <div>

    <link href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1" rel="stylesheet" type="text/css">
    <div class="header">
      <img alt="Vue logo" src="../assets/allaria.png" class="logo">
      </div>
    <transition 
    name="custom-classes-transition"
    enter-active-class="animated slideInRight"
    leave-active-class="animated slideOutLeft"
    > 
  <v-row class="d-flex justify-center " v-if="display">
  <v-col cols="12" sm="12" md="6" lg="5" xl="4">
      <v-card elevation="5" class="maincard" style="background-color: #fbfbfb" v-if="display">
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <h1>Datos Personales</h1>
            <div class="dot-div">
              <fa icon="circle" class="this-dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
              <fa icon="circle" class="dot" />
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
           <v-col cols="10">
           
            <p class="subtitulo">
              Por favor, verificá que tus datos sean correctos.
            </p>
          </v-col>
          <v-col cols="10" sm="7" md="6">
            <div class="fields" >
            <v-text-field
              v-model="fnacimiento"
              outlined
              dense
              hide-details
              label="Fecha de nacimiento"
              style="margin-top: 30px; margin-bottom: 25px;"
              @keypress="blockinput($event)"
              class="date-field"
            >
              <template v-slot:append>
                <date-picker v-model="fnacimiento"/>
              </template>
            </v-text-field>

          

            <v-text-field
              label="Lugar de Nacimiento"
              
              hide-details="auto"
              v-model="lugarnacimiento"
              outlined
              @change="validar()"            
              @keyup="validar()"
              dense
              style="margin-top: 26px; margin-bottom: 25px"
            ></v-text-field>

            <v-select :items="nacionalidades" label="Nacionalidad" outlined dense v-model="nacionalidad" @change="validar"></v-select>
            <v-select :items="estadoscivilesdesc" label="Estado civil" outlined dense v-model="estadocivilseleccionado" @change="validar"></v-select>
  </div>
            
            <button @click="continuar()" class="continuar" :disabled="!validado">Continuar</button>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
      </transition>
      
  </div>
</template>

<script>
import DatePicker from "../components/DatePicker.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    DatePicker,
  },
  data() {
    return {
      fnacimiento: "",
      lugarnacimiento: "",
      nacionalidad: "",
      estadocivil: 'Soltero',
      estadosciviles: [],
      estadoscivilesdesc: [],
      estadocivilseleccionado: null,
      validado: true,
      paises: [],
      nacionalidades: [],
      lugaresdenacimiento: [],


      display: false,

    };
  },

  
  methods:{
    continuar(){


      this.$session.set('fnacimiento',  this.fnacimiento);
      this.$session.set('lugarnacimiento', this.lugarnacimiento);
      this.$session.set('nacionalidad', this.buscarNacionalidadId(this.nacionalidad));
      this.$session.set('estadocivil', this.buscarEstadoCivilId(this.estadocivilseleccionado));
      
      
    
      window.scrollTo(0, 0);

    this.display = false
    setTimeout(() => {  this.$router.push('/paso2'); }, 400);

      
    },
    validar(){

      if(this.fnacimiento.trim() == null || this.fnacimiento.trim() == '' || this.lugarnacimiento.trim() == '' || this.lugarnacimiento.trim() == null || this.nacionalidad.trim() == "" || this.nacionalidad.trim() == "" || this.estadocivilseleccionado == null){
        this.validado = false;
      } else {
        this.validado = true;
      }
    },
    async getPaises(){
      this.token = localStorage.getItem('token')

      const options = {
        method: 'get',
        url: process.env.VUE_APP_BACKURL + `/getPaises`,  
      };
      axios(options)
        .then((result) => {
          this.paises = result.data
          this.setItems();
         
      })
      .catch(() => {
       console.log('error getpaises')
      })
    },
    getEstadoCivil(){
      this.token = localStorage.getItem('token')

      const options = {
        method: 'get',
        url: process.env.VUE_APP_BACKURL + `/getEstadosCiviles`,  
      };
      axios(options)
        .then((result) => {
          this.estadosciviles = result.data
          this.setItems();
         
      })
      .catch(() => {
       console.log('error getpaises')
      })
    },
    setItems(){

      this.lugarnacimiento = this.$session.get("lugarnacimiento")
      let nacionalidad_id = this.$session.get("nacionalidad")

      this.paises.forEach(pais => {
         this.nacionalidades.push(pais.nacionalidad);
         this.lugaresdenacimiento.push(pais.descripcion);

        
          
          if(pais.id == nacionalidad_id){
              this.nacionalidad = pais.descripcion
          }
      });

      this.estadosciviles.forEach(e => {
         this.estadoscivilesdesc.push(e.descripcion)
      });

      this.fnacimiento = this.$session.get("fnacimiento")
    },
    buscarNacionalidadId(nacionalidad){
        let id = -1
        this.paises.forEach(pais => { 
            if(pais.nacionalidad == nacionalidad){
              id = pais.id
            }
        });
        return id
    },
    buscarEstadoCivilId(estado){
      let id = -1
        this.estadosciviles.forEach(e => { 
            if(e.descripcion == estado){
              id = e.id
            }
        });
        return id
    },
    buscarLugarNacimientoId(lugar){
      // unused
      let id = -1
        this.paises.forEach(pais => { 
            if(pais.descripcion == lugar){
              id = pais.id
            }
        });
        return id
    },
    blockinput: function (evt) {
      
        evt.preventDefault();
      
    },
  },
  mounted() {
    this.display = true
    this.validar();
    this.getPaises();
    this.getEstadoCivil();
     
  },
  watch: {
    fnacimiento(){
      this.validar();
    },
    lugarnacimiento(){
      this.validar();
    },
    nacionalidad(){
      this.validar();
    }
  }
};
</script>


<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');


*{
    font-family: 'Lato', sans-serif;
  }

.maincard {
  margin-top: 10%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 775px;
  background-color: black;
  color: black;
 
}

.maincard::-webkit-scrollbar {
  width: 12px;
}

.maincard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
  border-radius: 10px;
}

.maincard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(153, 153, 153);
}

.body {
  background-color: black;
}



.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}
.continuar {
  background-color: #0c5297;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.085em;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  border: none;
  margin-top: 125px;
  margin-bottom: 20px;
    
 
}
  
  .continuar:disabled {
    background-color: #D0D0D0;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.085em;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: none;
  }


  
h1 {
  font-size: 38px;
  padding-top: 10%;
  text-align: center;
  font-weight: 800;
}


.topmargin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.subtitulo {
  line-height: 20px;
  
   font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-top: 40px;
 
}

.fields{
    margin-top: 20px;
  }

.dot {
  color: #0c5297;
  opacity: 0.4;
  width: 10px;
}

.this-dot {
  color: #0c5297;
  width: 10px;
}
.dot-div {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.date-field{

  width: 800px;
}



@media (max-width: 960px){
  .maincard{
    margin-top: 0%;
  }

 
}





.txtfield {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 500px;
}


.slide-enter-active {
  transform: translate(100%, 0);
}
.slide-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translate(-100%, 0);
  
}
</style>